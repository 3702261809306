/* eslint-disable array-callback-return */
import React, { useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { MOBILE_SCREEN } from 'Constants/display'
import { authLogout } from 'redux/actions'
import color from 'assets/css/colors.scss'

import { icon_nav_toggle, icon_times } from 'components/FontAwesome'
import Spinner from 'components/Spinner'
import NavSearch from './NavSearch'
import NavAccount from './NavAccount'
import NavFeatureMenu from './NavFeatureMenu'
import NavLogo from './NavLogo'
import MobileMenu from './MobileMenu'
import { debounce } from 'lodash'

const PrivateNavbar = ({ authLogout, isAdmin, userInfo }) => {
  const location = useLocation()
  const history = useHistory()
  const isMobile = useMediaQuery({ query: MOBILE_SCREEN })

  const [isOpen, setOpen] = useState(false)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    const closeNavMenu = debounce(
      () => {
        setOpen(false)
      },
      1000,
      { leading: true }
    )

    closeNavMenu()
  }, [location.pathname])

  useEffect(() => {
    if (userInfo.profile) {
      setLoading(false)
    }
  }, [userInfo])

  const dontRender = [
    /login-mitra/,
    /evaluasi\/peraturan/,
    /evaluasi\/antar-peraturan/,
  ]
  const condition = dontRender.some((x) => x.test(history.location.pathname))
    ? 'none'
    : 'block'


  const openInNewTab = useMemo(() => {
    return isMobile ? "" : "_blank"
  }, [isMobile])


  return (
    <NavEclis style={{ display: condition }}>
      <Wrapper className="container">
        <div className="nav_header">
          <NavLogo isLoaded={!isLoading} />

          {!isLoading && <NavSearch />}

          {!isMobile && (
            <div className="eclis_nav_desktop">
              <List>
                {userInfo.membership === 'free' && (
                  <Link className="up" to="/upgrade">
                    Go Premium
                  </Link>
                )}
              </List>
              <List>{!isLoading ? <NavFeatureMenu /> : null}</List>
              <List>
                {!isLoading ? (
                  <NavAccount isAdmin={isAdmin} />
                ) : (
                  <Spinner center={false} size={23} fill="#fff" />
                )}
              </List>
            </div>
          )}

          {
            /* render when mobile */
            isMobile && (
              <span className="eclis_nav_toggle" onClick={() => setOpen(!isOpen)}>
                {isOpen ? icon_times : icon_nav_toggle}
              </span>
            )
          }
        </div>

        {isMobile && (
          <ul className={`main-nav ${isOpen ? 'active' : ''}`}>
            <>
              <li>
                {' '}
                Profil Saya
                <ul>
                  <li>
                    {' '}
                    <Link to="/dashboard"> Dashboard </Link>{' '}
                  </li>
                  <li>
                    {' '}
                    <Link to="/dashboard/profile"> Profil Saya </Link>{' '}
                  </li>
                  <li>
                    <a
                      target={openInNewTab}
                      rel="noopener noreferrer"
                      href="https://old.eclis.id"
                    >
                      {' '}
                      Old Eclis{' '}
                    </a>
                  </li>
                
                  <li
                    style={{ display: 'inline-block', cursor: 'pointer' }}
                    onClick={() => authLogout()}
                  >
                    Keluar
                  </li>
                </ul>
              </li>
              <li>
                {' '}
                Fitur eCLIS
                <ul>
                  {' '}
                  <MobileMenu />{' '}
                </ul>
              </li>
            </>
          </ul>
        )}
      </Wrapper>
    </NavEclis>
  )
}

const mapStateToProps = (state) => {
  return {
    isAdmin: state.isAdmin,
    hasExclusiveFeature: state.hasExclusiveFeature,
    userInfo: state.userInfo,
  }
}
export default connect(mapStateToProps, { authLogout })(PrivateNavbar)

const NavEclis = styled.nav`
  user-select: none;
  position: relative;
  background-color: ${color.primary};
`

const Wrapper = styled.div`
  display: block;
  width: 100%;

  .nav_header {
    padding: 1rem 0rem;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .eclis_nav_toggle {
      font-size: 26px;
      color: #fff;
      cursor: pointer;
    }
  }

  ul.main-nav {
    overflow: hidden;
    max-height: 0px;
    transition: all 0.3s ease;

    li {
      padding-block-start: 0.5rem;
      padding-block-end: 0.5rem;
      list-style-type: none;
      color: ${rgba('#fff', 0.7)};
      font-weight: 600;

      svg {
        font-size: 14px;
      }

      a {
        color: #fff !important;
        &:hover {
          text-decoration: none;
        }
      }
    }

    &.active {
      margin-top: 1rem;
      overflow: hidden;
      max-height: 550px !important;
    }
  }

  /* DESKTOP VERSION */
  @media screen and (min-width: 768px) {
    margin: 0 auto;
    height: 75px;

    .nav_header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .eclis_nav_toggle {
        display: none;
      }

      .eclis_nav_desktop {
        display: flex;
        flex-direction: row;
      }
    }

    ul.main-nav {
      padding-inline-start: 0rem;
      overflow: visible;

      li {
        position: relative;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        color: initial;
        font-weight: normal;
        cursor: pointer;

        svg {
          font-size: 10px;
        }

        &:hover ul {
          display: block;
        }

        a {
          color: ${color.black} !important;
          &:hover {
            text-decoration: none;
          }
        }
      }

      &.active {
        overflow: visible;
        max-height: 500px;
      }
    }
  }
`

let List = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 1.5rem;

  a.up {
    color: #fff;
    font-size: 13.5px;
    font-weight: 600;
    padding: 0.4rem 0.6rem;
    background-color: ${rgba('#ffffff', 0.07)};
    border-radius: 3px;
    text-decoration: none !important;

    &:hover {
      background-color: ${rgba('#ffffff', 0.13)};
    }
  }

  @media screen and (max-width: 1000px) {
    a.up {
      display: none;
    }
  }
`
