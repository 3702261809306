import React from 'react'
import styled from 'styled-components'

const ComponentTest = () => {
  return <Div></Div>
}

export default ComponentTest

const Div = styled.div`
  min-height: 600px;
`
