import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

const Logo = ({ className, domainAssets, APIFetchUrl }) => {
  if (APIFetchUrl.indexOf("pertaminajbb") >= 0) {
    return <Img src={`${APIFetchUrl}/public/systems/legal-counsel-logo.png`} className={className} />
  }else if (domainAssets.logo) {
    return <Img src={`${APIFetchUrl}/${domainAssets.logo}`} className={className} />
  }

  return null
}

const mapStateToProps = (state) => {
  return {
    domainAssets: state.domainAssets,
    APIFetchUrl: state.APIFetchUrl,
  }
}
export default connect(mapStateToProps)(Logo)

const Img = styled.img`
  width: auto;
  height: auto;
  max-height: 43px;
`
