import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useMemo,
} from 'react'

// Create Tabs Context
const TabParent = createContext()

const Tabs = ({ children }) => {
  const [activeTab, setActive] = useState('')

  // store tab available
  let store = useMemo(() => [], [])

  // set first tab in the first render
  useEffect(() => {
    setActive(store[0])
  }, [store])

  return (
    <TabParent.Provider
      value={{
        activeTab: activeTab,
        setActive: (tab) => {
          setActive(tab)
        },
        storeTab: (tab) => {
          store = [...store, tab]
        },
      }}
    >
      {children}
    </TabParent.Provider>
  )
}

const Tab = ({ children, id, isDisabled }) => {
  const val = useContext(TabParent)

  useEffect(() => {
    val.storeTab(id)
  }, [id, val])

  const isActive = val.activeTab === id ? 'isActive' : ''

  return (
    <div
      onClick={() => {
        if (!isDisabled) {
          val.setActive(id)
        }
      }}
      className={isActive}
      style={{ cursor: 'pointer' }}
    >
      {children}
    </div>
  )
}

const TabContent = ({ children, forId, className }) => {
  const val = useContext(TabParent)

  const isActive = val.activeTab === forId ? 'block' : 'none'

  return (
    <div style={{ display: isActive }} className={className}>
      {children}
    </div>
  )
}

export { Tabs, Tab, TabContent }
