import axios from 'axios'
import Cookies from 'js-cookie'
import history from 'routes/history'
import { setCookiesExpired, isSubDomain } from 'helper'
import store from 'redux/store'
import { API_REQUEST_JWT } from 'Constants/api'

export const baseUrl = axios.create({
  baseURL: isSubDomain()
    ? `${window.location.origin}`
    : process.env.REACT_APP_BASE_URL,
})

baseUrl.interceptors.request.use((config) => {
  config.headers['authorization'] = `Bearer ${Cookies.get('access_jwt')}`

  if (config.data instanceof FormData) {
    config.headers['Content-Type'] = 'multipart/form-data'
    config.headers['Accept'] = 'multipart/form-data'
  } else {
    config.headers['Content-Type'] = 'application/json'
  }

  return config
})

baseUrl.interceptors.response.use(
  (res) => res,
  async (error) => {
    if (error.response && error.response.status === 401) {
      const hasAccestToken = Cookies.get('access_jwt')
      let result

      // cek dulu apakah ada access jwt di cookies sebelum refresh token
      // jika ga ada, maka skip karena pasti ditolak
      if (hasAccestToken) {
        await unauthorizedHandler(error)
        await callingAgain(error.config.url, error.config.method).then((res) => {
          result = res
        })

        return result
      }

      return Promise.reject(error)
    } else if (error.response && error.response.status === 403) {
      store.dispatch({ type: 'LOGOUT' })
      Cookies.remove('access_jwt')
      Cookies.remove('refresh_jwt')
      Cookies.remove('unique')

      return Promise.reject(error)
    } else {
      return Promise.reject(error)
    }
  }
)

const unauthorizedHandler = async () => {
  return requestNewToken()
    .then((res) => {
      return Promise.resolve(res)
    })
    .catch((err) => {
      return Promise.reject(err)
    })
}

const newToken = axios.create()
export const requestNewToken = async () => {
  let url = isSubDomain()
    ? `${window.location.origin}`
    : process.env.REACT_APP_BASE_URL

  return new Promise((resolve, reject) => {
    newToken({
      method: 'post',
      url: `${url}/${API_REQUEST_JWT}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Cookies.get('refresh_jwt')}`,
      },
      data: {
        access_jwt: Cookies.get('access_jwt'),
      },
    })
      .then((res) => {
        Cookies.set('access_jwt', res.data.access_jwt, {
          expires: setCookiesExpired(process.env.REACT_APP_ACCESS_TOKEN_EXPIRED),
          domain: `${process.env.REACT_APP_COOKIES_DOMAIN}`,
        })
        Cookies.set('refresh_jwt', res.data.refresh_jwt, {
          expires: setCookiesExpired(process.env.REACT_APP_REFRESH_TOKEN_EXPIRED),
          domain: `${process.env.REACT_APP_COOKIES_DOMAIN}`,
        })

        resolve(res)
      })
      .catch((error) => {
        store.dispatch({
          type: 'LOGOUT',
          payload: {
            saveLastUrl: true,
          },
        })

        history.push('/login')

        reject(error)
      })
  })
}

const callingAgain = async (url, method) => {
  return new Promise((resolve, reject) => {
    baseUrl(
      {
        url: url,
        method: method,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookies.get('access_jwt')}`,
        },
      }
    )
      .then((res) => {
        return resolve(res)
      })
      .catch((error) => {
        if (error.response.status === 401) {
          return reject(error)
        } else {
          return reject(error)
        }
      })
  })
}
