import React from 'react'
import GlobalErrorFallback from './GlobalErrorFallback'
import * as Sentry from '@sentry/react'

const GlobalErrorBoundaries = ({ children }) => {
  const handleError = (error) => {
    Sentry.withScope((scope) => {
      scope.setTag('domain', 'globalApp')
      Sentry.captureException(error)
    })
  }

  return (
    <Sentry.ErrorBoundary fallback={GlobalErrorFallback} onError={handleError}>
      {children}
    </Sentry.ErrorBoundary>
  )
}

export default GlobalErrorBoundaries
