export const isPopupAdsOpen = (state = true, action) => {
  switch (action.type) {
    case 'OPEN_POPUP_ADS':
      return true

    case 'CLOSE_POPUP_ADS':
      return false

    default:
      return state
  }
}
