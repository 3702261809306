export const isSubDomain = (state = true, action) => {
  switch (action.type) {
    case 'SET_IS_SUB_DOMAIN':
      return true
    case 'SET_NOT_SUB_DOMAIN':
      return false
    default:
      return state
  }
}
