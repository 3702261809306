import React from 'react'
import { connect } from 'react-redux'
import PrivateNavbar from './PrivateNavbar'
import PublicNavbar from './PublicNavbar'

const Navbar = ({ isLogin, userInfo }) => {
  if (isLogin) {
    if (userInfo.profile) {
      return <PrivateNavbar />
    }

    return null
  }

  return <PublicNavbar />
}

const mapStateToProps = (state) => {
  return {
    isLogin: state.isLogin,
    userInfo: state.userInfo,
  }
}

export default connect(mapStateToProps)(Navbar)
