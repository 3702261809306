import React from 'react'
import styled from 'styled-components'
import logo from 'assets/img/Logo/logo-putih-buku.png'

const LogoBuku = (props) => {
  return <Img src={logo} className={props.className} />
}
export default LogoBuku

const Img = styled.img`
  width: 50px;
  height: 50px;
`
