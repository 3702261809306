// Authentication
export const API_LOGIN = `oauth/login`
export const API_REGISTER = `oauth/registration`
export const API_GET_ADVERTISEMENT = `oauth/fetch/advertisments`
export const API_GET_DOMAIN_ASSETS = `oauth/information`
export const API_GET_USER_INFO = `oauth/info`
export const API_REQUEST_JWT = `oauth/request-jwt`
export const API_POST_LOG_ERROR = `oauth/log/error`
export const API_POST_FORGOT_PASSWORD = `oauth/user/forgot-password`
export const API_POST_TICKET = `oauth/ticket`
export const API_PUT_PROFILE = `oauth/profile`
export const API_POST_CHANGE_PASSWORD = `oauth/user/change-password`
export const API_PUT_CHANGE_AVATAR = `oauth/profile/photo`
export const API_GET_INVOICE_LIST = `oauth/membership/orders?`
export const API_GET_INVOICE_SHEET = (id) => `oauth/membership/order?id=${id}`
export const API_GET_TICKETS_LIST = (page) => `oauth/tickets?page=${page}`
export const API_GET_MEMBERSHIP = `oauth/memberships?pages&search`
export const API_POST_UPGRADE_MEMBERSHIP = `oauth/membership/order`
export const API_GET_VALID_SESSION = `oauth/valid-session`
