import React from 'react'
import styled from 'styled-components'
import color from 'assets/css/colors.scss'

const Spinner = ({
  size = 35,
  borderSize = 3,
  fill = color.primary,
  center = true,
  className = '',
}) => {
  if (center) {
    return (
      <Center>
        <Spin
          size={size}
          color={fill}
          borderSize={borderSize}
          className={className}
        />
      </Center>
    )
  }

  return (
    <Spin size={size} color={fill} borderSize={borderSize} className={className} />
  )
}

export default Spinner

const Spin = styled.div`
  display: inline-block;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  box-sizing: border-box;
  border: ${({ borderSize }) => `solid ${borderSize}px transparent`};
  border-top-color: ${({ color }) => color};
  border-left-color: ${({ color }) => color};
  border-radius: 50%;
  animation: rolling 600ms linear infinite;

  @keyframes rolling {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const Center = styled.div`
  width: 100%;
  padding: 1rem 0rem;
  display: flex;
  justify-content: center;
  align-items: center;
`
