import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'

const firebaseConfig = {
  apiKey: 'AIzaSyAJ20ziDE3VXoMcNLKA5jl5zN8p5-omSW8',
  authDomain: 'eclis-d66c8.firebaseapp.com',
  projectId: 'eclis-d66c8',
  storageBucket: 'eclis-d66c8.appspot.com',
  messagingSenderId: '20413795474',
  appId: '1:20413795474:web:7703e7cc87d7f8967f2a4b',
  measurementId: 'G-HVB8KBS41J',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const analytics = getAnalytics(app)
