import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MIN_QUERY_SEARCH_LENGTH } from 'Constants/global'
import { useDetectClickOutsideElement } from 'hooks'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

const SearchType = ['eClis', 'Kompilasi', 'Definisi Hukum', 'Himpunan Catatan']


const NavSearchType = ({ searchType, searchFocus, setSearchFocus, value }) => {
  const [searchOpen, setSearchOpen] = useState(false)
  const searchRef = useRef()
  const history = useHistory()

  useDetectClickOutsideElement(searchRef, () => setSearchOpen(false))

  const searchFiltered = useMemo(() => {
    return searchType?.filter((x) => x !== searchFocus)
  }, [searchFocus, searchType])

  const handleSubmit = useCallback(
    (searchFocusVal) => {

      if (value.length >= MIN_QUERY_SEARCH_LENGTH) {
        let finalReplace = ''

        switch (searchFocusVal) {
          case SearchType[0]:
            finalReplace = `/search?search=${value}&sort=hierarki`
            break
          case SearchType[1]:
            finalReplace = `/kompilasi?search=${value}`
            break
          case SearchType[2]:
            finalReplace = `/definisi?search=${value}`
            break
          case SearchType[3]:
            finalReplace = `/notes?type=content&scope=all&search=${value}`
            break
          default:
            finalReplace = `/search?search=${value}&sort=hierarki`
        }

        history.replace(finalReplace)
      }
    },
    [value, history]
  )

  return (
    <div>
      <WrapperType onClick={() => setSearchOpen((old) => !old)}>
        {searchFocus}

        <FontAwesomeIcon
          icon={['fas', searchOpen ? 'angle-up' : 'angle-down']}
          style={{
            fontSize: '20px',
            marginLeft: 15,
            marginRight: 10,
          }}
        />
      </WrapperType>
      <div style={{ position: 'relative' }}>
        {searchOpen && (
          <WrapperOption ref={searchRef}>
            <div>
              {searchFiltered.map((x, idx) => {
                return (
                  <div
                    key={idx}
                    className="children"
                    onClick={() => {
                      setSearchFocus(x)
                      setSearchOpen(false)
                      handleSubmit(x)
                      // setValue("")
                    }}
                  >
                    {x}
                  </div>
                )
              })}
            </div>
          </WrapperOption>
        )}
      </div>
    </div>
  )
}

const WrapperType = styled.div`
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  min-width: 50px;
  height: 40px;
  white-space: nowrap;
  padding-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  background: #d6e6fb;
  color: #3181f5;
`

const WrapperOption = styled.div`
  border: 1px solid #d6e6fb;
  right: 0;
  margin-top: 2.5px;
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 10001;
  min-width: 50px;
  width: auto;
  white-space: nowrap;
  color: #3181f5;
  background: white;
  cursor: pointer;

  .children {
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 15px;
    padding-left: 15px;
  }

  .children:hover {
    background: #3181f5;
    color: white;
  }
`

export default NavSearchType
