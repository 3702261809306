import React from 'react'

const RefundPolicy = () => {
  return (
    <div>
      <center>
        <h3>Pengembalian Pembayaran</h3>
      </center>
      <h4>Kondisi Pengembalian biaya berlangganan</h4>
      <p>
        eCLIS.id tidak melayani ganti rugi atas biaya berlangganan, kecuali dalam
        kondisi pengguna melakukan pembayaran yang melebihi biaya berlangganan yang
        telah ditetapkan. Silakan hubungi kami melalui email customercare@eclis.id
        apabila:
        <ol>
          <li>
            pengguna ingin melakukan klaim atas kelebihan pembayaran, dengan
            menyisipkan bukti pembayaran dalam email yang dikirim kepada kami, atau;
          </li>
          <li>terdapat keluhan mengenai fitur berlangganan yang Anda gunakan;</li>
        </ol>
      </p>

      <h4>Estimasi Biaya Pengembalian</h4>
      <p>
        Pembayaran biaya pengembalian dilaksanakan paling lambat dalam 7 (tujuh) hari
        sejak transaksi, melalui rekening pengguna yang terdaftar dalam akun
        berlangganan eCLIS.id
      </p>

      <h4>Pembatalan berlangganan</h4>
      <p>
        Jika Anda tidak lagi menginginkan program berlangganan premium membership,
        Anda dapat membatalkan kapan pun dan terus memanfaatkan fitur berlangganan
        yang telah Anda bayar, hingga pembayaran bulanan Anda berikutnya jatuh tempo.
        Setelah itu, jenis akun Anda akan beralih menjadi Free Member.
      </p>
    </div>
  )
}
export default RefundPolicy
