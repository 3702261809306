import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './navbar.scss'
import DropdownWithArrow from 'components/DropdownWithArrow'

const SubMenuJasa = () => {
  const userIcon = <FontAwesomeIcon icon={['fas', 'user']} className="fa-fw" />
  const governmentIcon = (
    <FontAwesomeIcon icon={['fas', 'landmark']} className="fa-fw" />
  )

  return (
    <DropdownWithArrow minWidth="260px" className="sub_menu">
      <li>
        <Link className="sub_menu_item" to="/jasa/pengguna-individu">
          {userIcon} Pengguna Individu
          <div> Informasi terkait jenis keanggotaan kami </div>
        </Link>
      </li>
      <li>
        <Link className="sub_menu_item" to="/jasa/organisasi">
          {governmentIcon} Organisasi
          <div> Solusi tepat bagi pengguna eCLIS sebagai organisasi </div>
        </Link>
      </li>
    </DropdownWithArrow>
  )
}
export default SubMenuJasa
