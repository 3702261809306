import { baseUrl } from 'api'
import axios from 'axios'
import history from 'routes/history'

import { isSubDomain, setCookiesExpired, userMembership } from 'helper'
import Cookies from 'js-cookie'
import { isObject } from 'lodash'
import {
  API_LOGIN,
  API_GET_ADVERTISEMENT,
  API_GET_USER_INFO,
  API_GET_DOMAIN_ASSETS,
} from 'Constants/api'
import * as Sentry from '@sentry/react'

export const userSession = (status, options) => (dispatch) => {
  let saveLastVisitToCookies

  if (isObject(options)) {
    saveLastVisitToCookies = options.saveLastUrl ? options.saveLastUrl : true
  }

  if (status === 'login') {
    dispatch({
      type: 'LOGIN',
    })
  }

  if (status === 'logout') {
    if (saveLastVisitToCookies) {
      dispatch({
        type: 'LOGOUT',
        payload: {
          saveLastUrl: true,
        },
      })
    } else {
      dispatch({
        type: 'LOGOUT',
      })
    }
  }
}

export const authLogin =
  (values, { setSubmitting, setStatus }) =>
  (dispatch) => {
    baseUrl
      .post(API_LOGIN, {
        email: values.email,
        password: values.password,
      })
      .then((res) => {
        Cookies.set('access_jwt', res.data.access_jwt, {
          expires: setCookiesExpired(process.env.REACT_APP_ACCESS_TOKEN_EXPIRED),
          domain: `${process.env.REACT_APP_COOKIES_DOMAIN}`,
        })
        Cookies.set('refresh_jwt', res.data.refresh_jwt, {
          expires: setCookiesExpired(process.env.REACT_APP_REFRESH_TOKEN_EXPIRED),
          domain: `${process.env.REACT_APP_COOKIES_DOMAIN}`,
        })
        Cookies.set('_id', res.data.unique, {
          expires: setCookiesExpired(process.env.REACT_APP_REFRESH_TOKEN_EXPIRED),
          domain: `${process.env.REACT_APP_COOKIES_DOMAIN}`,
        })

        // set context of Sentry User
        Sentry.setUser({
          username: res.data.client.profile.name || '',
          email: res.data.client.profile.email || '',
        })

        // save user exclusive features to redux state
        dispatch({
          type: 'SET_FEATURE',
          payload: res.data.client.menu.dashboard,
        })

        // set popup ads open to true
        dispatch({ type: 'SET_POPUP_ADS_DISPLAY' })

        // authenticate user to login
        dispatch({ type: 'LOGIN' })

        if (values.redirect === 'redirect') {
          window.open(`${process.env.REACT_APP_ADMIN_URL}/dashboard`)
        } else if (Cookies.get('lastVisit')) {
          const visited = Cookies.getJSON('lastVisit')

          if (visited.origin !== window.origin) {
            window.location.replace(visited.fullpath)
          } else {
            history.push(`${visited.pathname}${visited.search}`)
          }
        } else {
          history.push('/')
        }
      })
      .catch((err) => {
        setStatus({ error: err.response.data.errors })
        setSubmitting(false)
      })
  }

export const authLogout = () => (dispatch) => {
  Cookies.remove('access_jwt', {
    path: '/',
    domain: process.env.REACT_APP_COOKIES_DOMAIN,
  })
  Cookies.remove('refresh_jwt', {
    path: '/',
    domain: process.env.REACT_APP_COOKIES_DOMAIN,
  })
  Cookies.remove('_id', {
    path: '/',
    domain: process.env.REACT_APP_COOKIES_DOMAIN,
  })

  Cookies.remove('AUTH_SESSION_ID', {
    path: '/auth/realms/ub/',
    domain: 'iam.ub.ac.id',
  })
  Cookies.remove('KEYCLOAK_IDENTITY', {
    path: '/auth/realms/ub/',
    domain: 'iam.ub.ac.id',
  })
  Cookies.remove('KEYCLOAK_SESSION', {
    path: '/auth/realms/ub/',
    domain: 'iam.ub.ac.id',
  })

  // remove Sentry User from context
  Sentry.configureScope((scope) => scope.setUser(null))

  dispatch({
    type: 'SET_USER_INFORMATION',
    payload: {},
  })

  dispatch({ type: 'LOGOUT' })
}

export const setAdmin = (status) => {
  switch (status) {
    case true:
      return { type: 'IS_ADMIN' }
    case false:
      return { type: 'IS_NOT_ADMIN' }
    default:
      break
  }
}

export const setExclusiveFeature = (feature) => (dispatch) => {
  dispatch({
    type: 'SET_FEATURE',
    payload: feature,
  })
}

export const setListOfAds = (isLogin, membership) => (dispatch) => {
  if (isLogin && membership === 'free') {
    baseUrl
      .get(API_GET_ADVERTISEMENT)
      .then((res) => {
        dispatch({
          type: 'SET_ADS',
          payload: res.data.records,
        })
      })
      .catch((err) => {})
  }
}

export const fetchDomainAssets = () => (dispatch) => {
  let url = isSubDomain()
    ? `${window.location.origin}`
    : process.env.REACT_APP_BASE_URL

  axios
    .get(`${url}/${API_GET_DOMAIN_ASSETS}`)
    .then((res) => {
      dispatch({
        type: 'SET_DOMAIN_ASSETS',
        payload: res.data.system,
      })
    })
    .catch((err) => {})
}

export const checkIsSubDomain = () => (dispatch) => {
  let url = window.location.host

  if (isSubDomain()) {
    const domain = url.split('.')

    // if delta, read as not a sub domain
    if (domain[0] === 'dev') {
      return dispatch({ type: 'SET_NOT_SUB_DOMAIN' })
    }

    return dispatch({ type: 'SET_IS_SUB_DOMAIN' })
  }

  return dispatch({ type: 'SET_NOT_SUB_DOMAIN' })
}

export const setUserInformations = (isLogin) => (dispatch) => {
  if (isLogin) {
    baseUrl
      .post(API_GET_USER_INFO)
      .then((res) => {
        // set admin state
        if (res.data.menu.admin.length > 0) {
          dispatch({ type: 'IS_ADMIN' })
        } else {
          dispatch({ type: 'IS_NOT_ADMIN' })
        }

        Sentry.setUser({
          username: res.data.profile.name || '',
          email: res.data.profile.email || '',
        })

        // set user info
        dispatch({
          type: 'SET_USER_INFORMATION',
          payload: {
            ...res.data,
            isAdmin: res.data.menu.admin.length > 0,
            membership: userMembership(res.data.profile.membership.name),
          },
        })

        dispatch({
          type: 'SET_FEATURE',
          payload: res.data.menu.dashboard,
        })
      })
      .catch((err) => {})
  }
}
