import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import Menu from 'assets/img/MenuButton.png'
import DropdownFeature from './DropdownFeature'
import color from 'assets/css/colors.scss'
import ReactModalAnimatedWithoutTitle from 'components/ModalAnimatedWithoutTitle'
import { Link } from 'react-router-dom'

const NavFeatureMenu = () => {
  const [isOpen, setOpen] = useState(false)
  const [openModalExclusiveFeature, setOpenModalExclusiveFeature] = useState(false)
  const [currentTitle, setCurrentTitle] = useState('')

  const openDangerFeature = useCallback((title) => {
    setOpen(false)
    setOpenModalExclusiveFeature(true)
    setCurrentTitle(title)
  }, [])

  return (
    <Wrapper>
      <Img alt="menu" src={Menu} onClick={() => setOpen(true)} />
      {isOpen && (
        <DropdownFeature
          setOpen={(x) => setOpen(x)}
          openDangerFeature={(x) => openDangerFeature(x)}
        />
      )}
      <ReactModalAnimatedWithoutTitle
        isOpen={openModalExclusiveFeature}
        onRequestClose={() => setOpenModalExclusiveFeature(false)}
      >
        <div className="container">
          <p style={{ fontSize: '22px' }}>
            <b>
              Anda tidak memiliki hak akses ke fitur{' '}
              <span style={{ color: color.primary }}>{currentTitle}</span>.
            </b>
          </p>
          <p>
            Status keanggotaan anda tidak memiliki fitur {currentTitle}. Cek fitur
            apa saja yang dimiliki setiap keanggotaan{' '}
            {/* <Link to="/dashboard/membership/upgrade">disini</Link> */}
            <Link to="/upgrade" onClick={() => setOpenModalExclusiveFeature(false)}>
              disini
            </Link>
          </p>

          <br />
        </div>
      </ReactModalAnimatedWithoutTitle>
    </Wrapper>
  )
}

export default NavFeatureMenu

const Wrapper = styled.div`
  position: relative;
  cursor: pointer;
`

const Img = styled.img`
  height: 25px;
  width: 25px;
`
