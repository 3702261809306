export const isAdmin = (state = false, action) => {
  switch (action.type) {
    case 'IS_ADMIN':
      return true
    case 'IS_NOT_ADMIN':
      return false
    default:
      return state
  }
}
