import { combineReducers } from 'redux'
import { isLogin } from './isLogin'
import { hasExclusiveFeature } from './hasExclusiveFeature'
import { hasListOfAds } from './hasListOfAds'
import { isAdmin } from './isAdmin'
import { isSubDomain } from './isSubDomain'
import { domainAssets } from './domainAssets'
import { APIFetchUrl } from './APIFetchUrl'
import { userInfo } from './userInfo'
import { isPopupAdsDisplayed } from './isPopupAdsDisplayed'
import { isPopupAdsOpen } from './isPopupAdsOpen'

import { definisiPage } from './pageReducers/definisiPage'
import { evaluasiPage } from './pageReducers/evaluasiPage'
import { himpunanCatatanPage } from './pageReducers/himpunanCatatanPage'
import { kodifikasiHukumPage } from './pageReducers/kodifikasiHukumPage'
import { kompilasiHukumPage } from './pageReducers/kompilasiHukumPage'
import { pusatDataPage } from './pageReducers/pusatDataPage'
import { workingGroupPage } from './pageReducers/workingGroupPage'

export default combineReducers({
  isLogin,
  isAdmin,
  userInfo,
  hasExclusiveFeature,
  hasListOfAds,
  isPopupAdsDisplayed,
  domainAssets,
  isSubDomain,
  APIFetchUrl,
  definisiPage,
  evaluasiPage,
  himpunanCatatanPage,
  kodifikasiHukumPage,
  kompilasiHukumPage,
  pusatDataPage,
  workingGroupPage,
  isPopupAdsOpen,
})
