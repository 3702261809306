import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { Button } from 'components/Buttons'
import { rgba } from 'polished'
import { Helmet } from 'react-helmet'

const NotFound = () => {
  return (
    <Div>
      <Helmet>
        <title>404 Erorr | eCLIS</title>
      </Helmet>

      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="this_content">
              <span>404</span>
              <h1>Halaman Tidak Ditemukan</h1>
              <br />
              <br />

              <Link to="/">
                <ButtonStyled round>Kembali Ke Halaman Utama</ButtonStyled>
              </Link>
            </div>
          </div>
          <div className="col-md-6">
            <img
              alt="metadata"
              className="this_dummy"
              src={require('../assets/img/404.svg')}
            />
          </div>
        </div>
      </div>
    </Div>
  )
}
export default NotFound

const Div = styled.div`
  background-color: #2f2f2f;
  position: fixed;
  top: 0;
  z-index: 99999;
  height: 100%;
  width: 100%;

  div {
    height: 100%;
  }

  .this_content {
    margin-top: 5rem;
    color: #fff;

    span {
      font-size: 9rem;
      line-height: 1;
      font-weight: 700;
    }

    h1 {
      font-weight: 700;
      font-family: 6rem;
      color: #fff;
    }
  }

  .this_dummy {
    position: absolute;
    bottom: -80px;
    right: -100px;
  }
`

const ButtonStyled = styled(Button)`
  background-color: #fff;
  color: #333333;
  padding: 1.25rem 2rem !important;
  font-size: 1.2rem !important;
  box-shadow: 0px 5px 20px 0px ${rgba(255, 255, 255, 0.25)};
  border-radius: 3px;
`
