import React, { useMemo } from 'react'
import styled from 'styled-components'
import logo from 'assets/img/logo-warna.png'
import { motion } from 'framer-motion'
import color from 'assets/css/colors.scss'
import { BarLoader } from 'react-spinners'

const LoadingScreen = () => {
  return useMemo(() => {
    return (
      <Fixed initial={{ opacity: 1 }} exit={{ opacity: 0 }}>
        <Div>
          <div className="wrapper">
            <center>
              <img alt="logo" src={logo} />
            </center>
            <center>
              <BarLoader height={4} width={150} color={color.primary} />
            </center>
          </div>
        </Div>
      </Fixed>
    )
  }, [])
}
export default LoadingScreen

const Fixed = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background-color: #fff;
`

const Div = styled.div`
  background-color: #fff;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .wrapper {
    text-align: center;

    img {
      max-width: 230px;
      max-height: 120px;
      width: auto;
      height: auto;
      margin-bottom: 0.5rem;
    }
  }
`
