/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import qs from 'qs'
import ReactGA from 'react-ga4'

export const useDidUpdateEffect = (fn, dependencies) => {
  const didMountEffect = useRef(false)

  useEffect(() => {
    if (didMountEffect.current) fn()
    else didMountEffect.current = true
  }, dependencies)
}

export const useQueryParse = () => {
  const location = useLocation()
  const query = qs.parse(location.search, { ignoreQueryPrefix: true })

  return query
}

export const useDetectClickOutsideElement = (ref, func) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        func && func()
      }
    }

    setTimeout(() => {
      document.addEventListener('mousedown', handleClickOutside)
    }, 100)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}

export const useGoogleAnalytics = () => {
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ENV)
    // ReactGA.send('pageview')
    ReactGA.send({
      hitType: 'pageview',
      page: `${window.location.pathname}${window.location.search}`,
    })
    // console.log(process.env.REACT_APP_GOOGLE_ANALYTICS_ENV)
    // ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ENV, {
    //   // debug: false,
    //   // testMode: false,
    //   // debug: true,
    //   siteSpeedSampleRate: 100,
    // })
    // // console.log('cek', `${window.location.pathname}${window.location.search}`)
    // ReactGA.pageview(`${window.location.pathname}${window.location.search}`)
  }, [window.location.pathname, window.location.search])

  return null
}

export const useMultipleQuery = (data, query) => {
  let store = []

  const regex = new RegExp(`${query}`, 'ig')

  data.map((str) => {
    if (regex.test(str)) {
      let matchQuery = str.replace(
        regex,
        ` <span class='highlight'>${query}</span> `
      )
      return (store = [...store, matchQuery])
    }

    return (store = [...store, `${str}`])
  })

  return store
}
