import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './navbar.scss'
import DropdownWithArrow from 'components/DropdownWithArrow'

const SubMenuMitra = () => {
  const governmentIcon = (
    <FontAwesomeIcon icon={['fas', 'landmark']} className="fa-fw" />
  )

  return (
    <DropdownWithArrow minWidth="260px" className="sub_menu">
      <li>
        <Link className="sub_menu_item" to="/mitra-daerah">
          {governmentIcon} Daerah
          <div> Informasi terkait mitra daerah kami </div>
        </Link>
      </li>
      <li>
        <Link className="sub_menu_item" to="/mitra-universitas">
          {governmentIcon} Universitas
          <div> Akses halaman mitra universitas kami </div>
        </Link>
      </li>
    </DropdownWithArrow>
  )
}
export default SubMenuMitra
