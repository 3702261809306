export const APIFetchUrl = (
  state = process.env.REACT_APP_BASE_URL || '',
  action
) => {
  switch (action.type) {
    case 'SET_IS_SUB_DOMAIN':
      return `${window.location.origin}`
    case 'SET_NOT_SUB_DOMAIN':
      return process.env.REACT_APP_BASE_URL
    default:
      return state
  }
}
