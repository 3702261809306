/* eslint-disable array-callback-return */
import React, { useCallback, useRef } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { rgba } from 'polished'
import { useDetectClickOutsideElement, useDidUpdateEffect } from 'hooks'
import color from 'assets/css/colors.scss'

import DropdownWithArrow from 'components/DropdownWithArrow'
import { logEventAction } from 'helper/firebase/LogEvents'

const openAllFeature = [
  {
    id: 'ba2a6a60-9065-11ea-bf9f-ed3cd1d485bc',
    parent_id: null,
    hierarchy_level: 1,
    component: 'encyclopedia',
    label: 'Definisi Hukum',
    path: '/definisi',
    orders: 1,
  },
  {
    id: '641f53a0-9066-11ea-bf9f-ed3cd1d485bc',
    parent_id: null,
    hierarchy_level: 1,
    component: 'search_engine',
    label: 'Pusat Data',
    path: '/pusat-data',
    orders: 2,
  },
  {
    id: '7d71d450-9065-11ea-bf9f-ed3cd1d485bc',
    parent_id: null,
    hierarchy_level: 1,
    component: 'evaluation_analisis',
    label: 'Evaluasi Peraturan',
    path: '/evaluasi',
    orders: 2,
  },
  {
    id: '46632cb0-9066-11ea-bf9f-ed3cd1d485bc',
    parent_id: null,
    hierarchy_level: 1,
    component: 'workgroup_evaluation',
    label: 'Working Group',
    path: '/working-group',
    orders: 3,
  },
  {
    id: 'c8fcf8a0-9065-11ea-bf9f-ed3cd1d485bc',
    parent_id: null,
    hierarchy_level: 1,
    component: 'note_compilation',
    label: 'Himpunan Catatan',
    path: '/notes',
    orders: 3,
  },
  {
    id: '158d7d30-5609-11ea-b7b2-7be49ae3abc2',
    parent_id: null,
    hierarchy_level: 1,
    component: 'compilation',
    label: 'Kompilasi Hukum',
    path: '/kompilasi',
    orders: 8,
  },
  {
    id: '1b456ad0-5609-11ea-b7b2-7be49ae3abc2',
    parent_id: null,
    hierarchy_level: 1,
    component: 'codification',
    label: 'Kodifikasi Hukum',
    path: '/kodifikasi',
    orders: 9,
  },
  {
    id: 'fc8db7c1-5606-11ea-b7b2-7be49ae3abc2',
    parent_id: null,
    hierarchy_level: 1,
    component: 'constitution',
    label: 'Konstitusi Indonesia',
    path: '/konstitusi',
    orders: 9,
  },
  // {
  //   id: '123',
  //   parent_id: null,
  //   hierarchy_level: 1,
  //   component: 'search_engine',
  //   label: 'Rancangan Peraturan',
  //   path: '/rancangan-peraturan',
  //   orders: 10,
  // },
]

const DropdownFeature = ({ hasExclusiveFeature, setOpen, openDangerFeature }) => {
  const location = useLocation()
  const featureRef = useRef()

  useDetectClickOutsideElement(featureRef, () => setOpen(false))

  useDidUpdateEffect(() => {
    setOpen(false)
  }, [location.pathname])

  const handleFeatureClick = (featureName) => {
    return logEventAction({
      eventName: 'features_menu_click',
      param: {
        context: featureName,
        trigger: 'click',
      },
    })
  }

  const RenderFeatureMenu = useCallback(() => {
    // diubah ke semua fitur dibuka
    let render = openAllFeature.map((x) => {
      const found = hasExclusiveFeature.find((element) => element.label === x.label)

      if (x.label === 'Definisi Hukum') {
        // add this line for feature exception
        return found === undefined ? (
          <div
            className="exclude-div-button"
            key={x.label}
            onClick={() => {
              setOpen(false)
              openDangerFeature(x.label)
            }}
          >
            <img
              alt="definisi"
              className="feature-icon"
              src={require('assets/img/featureIcon/definisi.svg')}
            />
            <p>{x.label}</p>
          </div>
        ) : (
          <Link
            to={x.path}
            key={x.label}
            onClick={() => handleFeatureClick(x.label)}
          >
            <img
              alt="definisi"
              className="feature-icon"
              src={require('assets/img/featureIcon/definisi.svg')}
            />
            <p>{x.label}</p>
          </Link>
        )
      }
      if (x.label === 'Pusat Data') {
        return (
          <Link
            to={x.path}
            key={x.label}
            onClick={() => handleFeatureClick(x.label)}
          >
            <img
              alt="kodifikasi"
              className="feature-icon"
              src={require('assets/img/featureIcon/pusat-data.svg')}
            />
            <p>{x.label}</p>
          </Link>
        )
      }
      if (x.label === 'Kompilasi Hukum') {
        return (
          <Link
            to={x.path}
            key={x.label}
            onClick={() => handleFeatureClick(x.label)}
          >
            <img
              alt="kompilasi"
              className="feature-icon"
              src={require('assets/img/featureIcon/kompilasi.svg')}
            />
            <p>{x.label}</p>
          </Link>
        )
      }
      if (x.label === 'Kodifikasi Hukum') {
        return (
          <Link
            to={x.path}
            key={x.label}
            onClick={() => handleFeatureClick(x.label)}
          >
            <img
              alt="kodifikasi"
              className="feature-icon"
              src={require('assets/img/featureIcon/kodifikasi.svg')}
            />
            <p>{x.label}</p>
          </Link>
        )
      }
      if (x.label === 'Evaluasi Peraturan') {
        return found === undefined ? (
          <div
            className="exclude-div-button"
            key={x.label}
            onClick={() => {
              setOpen(false)
              openDangerFeature(x.label)
            }}
          >
            <img
              alt="evaluasi"
              className="feature-icon"
              src={require('assets/img/featureIcon/evaluasi.svg')}
            />
            <p>{x.label}</p>
          </div>
        ) : (
          <Link
            to={x.path}
            key={x.label}
            onClick={() => handleFeatureClick(x.label)}
          >
            <img
              alt="evaluasi"
              className="feature-icon"
              src={require('assets/img/featureIcon/evaluasi.svg')}
            />
            <p>{x.label}</p>
          </Link>
        )
      }
      if (x.label === 'Working Group') {
        return found === undefined ? (
          <div
            className="exclude-div-button"
            key={x.label}
            onClick={() => {
              setOpen(false)
              openDangerFeature(x.label)
            }}
          >
            <img
              alt="group"
              className="feature-icon"
              src={require('assets/img/featureIcon/group.svg')}
            />
            <p>{x.label}</p>
          </div>
        ) : (
          <Link
            to={x.path}
            key={x.label}
            onClick={() => handleFeatureClick(x.label)}
          >
            <img
              alt="group"
              className="feature-icon"
              src={require('assets/img/featureIcon/group.svg')}
            />
            <p>{x.label}</p>
          </Link>
        )
      }
      if (x.label === 'Himpunan Catatan') {
        return (
          <Link
            to={x.path}
            key={x.label}
            onClick={() => handleFeatureClick(x.label)}
          >
            <img
              alt="catatan"
              className="feature-icon"
              src={require('assets/img/featureIcon/catatan.svg')}
            />
            <p>{x.label}</p>
          </Link>
        )
      }

      if (x.label === 'Rancangan Peraturan') {
        return (
          <Link
            to={x.path}
            key={x.label}
            onClick={() => handleFeatureClick(x.label)}
          >
            <img
              alt="kodifikasi"
              className="feature-icon"
              src={require('assets/img/featureIcon/pusat-data.svg')}
            />
            <p>{x.label}</p>
          </Link>
        )
      }
    })

    return render
  }, [hasExclusiveFeature, openDangerFeature, setOpen])

  return (
    <DropdownArrowStyled width="365px">
      <div className="this_content" ref={featureRef}>
        <RenderFeatureMenu />
      </div>
    </DropdownArrowStyled>
  )
}

const mapStateToProps = (state) => {
  return {
    hasExclusiveFeature: state.hasExclusiveFeature,
  }
}
export default connect(mapStateToProps)(DropdownFeature)

const DropdownArrowStyled = styled(DropdownWithArrow)`
  top: 52px;
  right: -55px;
  padding: 1rem 1rem;
  display: flex;
  z-index: 10000;
  justify-content: center;
  align-items: center;
  z-index: 10000;

  a {
    width: 110px;
    min-height: 125px;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    padding: 0.5rem;
    vertical-align: top;

    p {
      margin-top: 0.5rem;
      font-weight: 600 !important;
      color: ${color.black} !important;
      line-height: 1.5;
      font-size: 14px;
    }

    img.feature-icon {
      width: 50px;
      height: 50px;
      filter: grayscale(100%);
    }

    &:hover {
      background-color: ${rgba(36, 182, 255, 0.1)}; /* #24B6FF */
      .feature-icon {
        filter: none;
      }
    }
  }

  .exclude-div-button {
    width: 110px;
    min-height: 125px;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    padding: 0.5rem;
    vertical-align: top;

    p {
      margin-top: 0.5rem;
      font-weight: 600 !important;
      color: ${color.black} !important;
      line-height: 1.5;
      font-size: 14px;
    }

    img.feature-icon {
      width: 50px;
      height: 50px;
      filter: grayscale(100%);
    }

    &:hover {
      background-color: ${rgba(36, 182, 255, 0.1)}; /* #24B6FF */
      .feature-icon {
        filter: none;
      }
    }
  }
`
