/* eslint-disable array-callback-return */
import React from 'react'
import { toast } from 'react-toastify'
import Cookies from 'js-cookie'

export const isSubDomain = () => {
  let path = window.location.host

  path = path.replace(new RegExp(/^\s+/), '')
  path = path.replace(new RegExp(/\s+$/), '')
  path = path.replace(new RegExp(/\\/g), '/')
  path = path.replace(new RegExp(/^http\\:\/\/|^https:\/\/|^ftp:\/\//i), '')

  path = path.replace(new RegExp(/^www\./i), '')
  path = path.replace(new RegExp(/\/(.*)/), '')

  if (path.match(new RegExp(/\.[a-z]{2,3}\.[a-z]{2}$/i))) {
    path = path.replace(new RegExp(/\.[a-z]{2,3}\.[a-z]{2}$/i), '')
  } else if (path.match(new RegExp(/\.[a-z]{2,4}$/i))) {
    path = path.replace(new RegExp(/\.[a-z]{2,4}$/i), '')
  }

  let subDomain = path.match(new RegExp(/\./g)) ? true : false

  return subDomain
}

export const formatNumber = (num) => {
  if (typeof num === 'string' || typeof num === 'number') {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  }

  return num
}

export const retry = (fn, retriesLeft = 4, interval = 1000) => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error)
            return
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject)
        }, interval)
      })
  })
}

export const setCookiesExpired = (minutes) => {
  let date = new Date()
  date.setTime(date.getTime() + minutes * 60 * 1000)

  return date
}

export const handleFormErrorResponse = (data) => {
  if (Array.isArray(data) && data.length > 1) {
    let store = []
    data.map((x, i) => {
      store = [...store, <li key={i}>{x.message}</li>]
    })

    return <div className="error_response_box">{store}</div>
  }

  return <center className="error_response_box">{data[0].message}</center>
}

export const setLastVisit = () => {
  if (window.location.pathname === '/') {
    Cookies.remove('lastVisit')
  } else {
    Cookies.set(
      'lastVisit',
      {
        origin: window.origin,
        pathname: window.location.pathname,
        search: window.location.search,
        fullpath: `${window.origin}${window.location.pathname}${window.location.search}`,
      },
      {
        expires: setCookiesExpired(30),
        domain: `${process.env.REACT_APP_COOKIES_DOMAIN}`,
      }
    )
  }
}

export const toastInfo = (msg, autoClose) => {
  toast.info(msg, {
    autoClose: autoClose,
    hideProgressBar: true,
  })
}

export const toastSuccess = (msg, autoClose = 1500, position = 'top-right') => {
  toast.success(msg, {
    autoClose: autoClose,
    hideProgressBar: true,
    position: position,
  })
}

export const toastError = (msg, autoClose = 1500, position = 'top-right') => {
  toast.error(msg, {
    autoClose: autoClose,
    hideProgressBar: true,
    position: position,
  })
}

export const setIndent = (indentLevel) => {
  switch (indentLevel) {
    case 0:
      return ''

    case 1:
      return 'indent1'

    case 2:
      return 'indent2'

    case 3:
      return 'indent3'

    case 4:
      return 'indent4'

    case 5:
      return 'indent5'

    case 6:
      return 'indent6'

    default:
      break
  }
}

export const member_benefit = (plan) => {
  const membership_benefit = [
    {
      key: 'search',
      feature: 'Pencarian tidak terbatas',
    },
    {
      key: 'compilation',
      feature: 'Kompilasi Peraturan',
    },
    {
      key: 'codification',
      feature: 'Kodifikasi Peraturan',
    },
    {
      key: 'metadata',
      feature: 'Metadata Struktur Peraturan',
    },
    {
      key: 'ads',
      feature: 'Tanpa Iklan',
    },
    {
      key: 'relate',
      feature: 'Keterkaitan Peraturan',
    },
    {
      key: 'analysis',
      feature: 'Analisis Peraturan',
    },
    {
      key: 'favorite',
      feature: 'Favorit Pasal dan Peraturan',
    },
  ]

  switch (plan) {
    case 'free':
      let free_benefit = []
      membership_benefit.map((x) => {
        if (
          x.key === 'search' ||
          x.key === 'compilation' ||
          x.key === 'codification' ||
          x.key === 'metadata'
        ) {
          free_benefit = [...free_benefit, x.feature]
        }
      })
      return free_benefit

    case 'premium':
      let premium_benefit = []
      membership_benefit.map((x) => {
        premium_benefit = [...premium_benefit, x.feature]
      })
      return premium_benefit

    default:
      break
  }
}

export const userMembership = (membership_name) => {
  if (membership_name.toLowerCase().includes('free')) {
    return 'free'
  }

  return 'premium'
}
