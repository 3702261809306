import { analytics } from './initializeFirebase'
import { logEvent } from 'firebase/analytics'

/**
 * Record an analytics event action
 * @param {object} event - Analytics event action object
 * @param {string} event.eventName - Event type name
 * @param {object} event.param - Parameter types of log action
 * @param {string} event.param.action - What trigger that occurred
 * @param {string} event.param.context - Url on element triggered
 * @param {string} event.param.trigger - How the element triggered
 * */
export const logEventAction = (event) => {
  return logEvent(analytics, event.eventName, {
    context: event.param.context,
    trigger: event.param.trigger,
  })
}
