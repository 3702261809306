import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import DropdownWithArrow from 'components/DropdownWithArrow'

const SubMenuProduk = () => {
  const searchIcon = <FontAwesomeIcon icon={['fas', 'search']} className="fa-fw" />
  const catatanIcon = (
    <FontAwesomeIcon icon={['fas', 'clipboard-list']} className="fa-fw" />
  )

  return (
    <DropdownWithArrow minWidth="240px" className="sub_menu">
      <li>
        <Link className="sub_menu_item" to="/produk/mesin-pencarian">
          {searchIcon} Mesin Pencarian
          <div>Rasakan cara baru dalam mencari dokumen hukum</div>
        </Link>
      </li>
      <li>
        <Link className="sub_menu_item" to="/produk/himpunan-catatan">
          {catatanIcon} Himpunan Catatan
          <div>Sarana kolaboratif untuk hukum yang lebih baik</div>
        </Link>
      </li>
    </DropdownWithArrow>
  )
}
export default SubMenuProduk
