import React from 'react'
import Modal from 'react-modal'
import Icon, { icon_times } from '../FontAwesome'
import { motion } from 'framer-motion'
import color from 'assets/css/colors.scss'
import './modal.scss'

const ReactModalAnimatedWithoutTitle = ({
  title,
  isOpen,
  onRequestClose,
  className,
  children,
}) => {
  const variants = {
    open: {
      translateY: ['-120px', '0px'],
      opacity: [0, 1],
    },
    closed: {
      translarteY: [1, 0.7],
      opacity: [1, 0],
    },
  }

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      contentLabel={title}
      className={className || 'modal_custom_default_without_title'}
      overlayClassName="modal_overlay"
      onRequestClose={() => onRequestClose()}
    >
      <motion.div
        className="modal_container_without_title"
        initial={false}
        transition={{ duration: 0.3, ease: 'easeOut' }}
        variants={variants}
        animate={isOpen ? 'open' : 'closed'}
      >
        <div className="modal_header">
          <div className="title">{title}</div>

          <div className="action">
            <Icon
              icon={icon_times}
              size="18px"
              hoverColor={color.neutral}
              onClick={() => onRequestClose()}
            />
          </div>
        </div>

        <div className="modal_body">{children}</div>
      </motion.div>
    </Modal>
  )
}
export default ReactModalAnimatedWithoutTitle
