import { rgba } from 'polished'
import React from 'react'
import styled from 'styled-components'

const DropdownWithArrow = ({
  minWidth,
  maxWidth,
  width,
  className,
  children,
  style,
  ref,
}) => {
  return (
    <Container
      minWidth={minWidth}
      maxWidth={maxWidth}
      width={width}
      className={className}
      ref={ref}
      style={style}
    >
      {children}
    </Container>
  )
}
export default DropdownWithArrow

let Container = styled.ul`
  position: absolute;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 4px 8px 0px ${rgba(0, 0, 0, 0.25)};
  border-radius: 5px;
  padding: 0.5rem 0rem;
  list-style: none;
  z-index: 9999;
  min-width: ${(props) => props.minWidth};
  max-width: ${(props) => props.maxWidth};
  width: ${(props) => props.width || '100%'};

  &::after,
  &::before {
    bottom: 100%;
    right: 16%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &::after {
    border-color: ${rgba(255, 255, 255, 0)};
    border-bottom-color: #fff;
    border-width: 8px;
    margin-left: -10px;
  }

  &::before {
    border-color: ${rgba(255, 255, 255, 0)};
    border-bottom-color: #fff;
    border-width: 11px;
    margin-left: -8px;
  }

  li {
    text-decoration: none !important;
  }
`
