import React from 'react'

const PrivacyPolicy = () => {
  return (
    <div>
      <center>
        <h3>Kebijakan Privasi</h3>
      </center>
      <p>
        Kebijakan Privasi eCLIS adalah penjelasan terkait data dan informasi pribadi
        Pengguna eCLIS, meliputi:
      </p>
      <ol>
        <li>Hukum dan Peraturan yang berlaku;</li>
        <li>Perolehan dan Perlindungan Data;</li>
        <li>Penggunaan, Penyimpanan, Pemanfaatan, dan Pengolahan Data</li>
        <li>Penghapusan Data;</li>
        <li>Pembatasan Tanggung Jawab eCLIS;</li>
        <li>Persetujuan</li>
        <li>Informasi Lebih Lanjut</li>
      </ol>

      <p>
        Kebijakan Privasi ini merupakan bagian tidak terpisahkan dan merupakan satu
        kesatuan dengan Ketentuan Penggunaan eCLIS.
      </p>

      <p>
        eCLIS berhak secara berkala memperbaharui Kebijakan Privasi ini menyesuaikan
        dengan pekembangan teknologi, hukum, dan kebutuhan Pengguna eCLIS. Jika ada
        perubahan yang dibuat pada Kebijakan Privasi ini, eCLIS akan memberikan
        keterangan “Terakhir Dirubah” disertai dengan tanggal perubahannya. eCLIS
        menganjurkan kepada Pengguna eCLIS untuk meninjau Kebijakan Privasi ini dari
        waktu ke waktu agar tetap mendapat informasi tentang bagaimana eCLIS
        mengelola data dan informasi pribadi Anda. Dengan menggunakan Layanan,
        Pengguna dianggap memberikan persetujuan eksplisit atas Kebijakan Privasi dan
        dengan terus menggunakan Layanan setelah revisi Kebijakan Privasi, Pengguna
        dianggap telah menyetujui Kebijakan Privasi yang diperbaharui.
      </p>

      <br />
      <h4>Hukum Dan Peraturan Yang Berlaku</h4>
      <p>
        eCLIS tunduk terhadap segala peraturan perundang-undangan dan kebijakan
        pemerintah Republik Indonesia yang berlaku, termasuk yang mengatur tentang
        informasi dan transaksi elektronik, penyelenggaraan sistem dan transaksi
        elektronik, dan perlindungan data pribadi Pengguna; termasuk segala peraturan
        pelaksana dan peraturan perubahan dari peraturan-peraturan tersebut yang
        mengatur dan melindungi penggunaan data dan informasi penting para Pengguna.
      </p>

      <br />
      <h4>Perolehan Dan Perlindungan Data</h4>
      <ol>
        <li>
          eCLIS berhak meminta data dan informasi Pengguna, antara lain sebagai
          berikut:
        </li>
        <ol>
          <li type="a">Informasi terkait akun eCLIS, berupa :</li>
          <ul>
            <li>Nama Lengkap</li>
            <li>Username</li>
            <li>E-mail</li>
            <li>
              Profil, antara lain tanggal lahir, jenis kelamin, profesi dan instansi
            </li>
            <li>Informasi transaksi keuangan</li>
          </ul>

          <li type="a">
            Perilaku Pengguna di eCLIS dan/atau selama menggunakan Layanan
          </li>
          <ul>
            <li>Informasi pilihan produk, fitur, dan layanan</li>
            <li>Informasi preferensi dan minat Pengguna</li>
            <li>Informasi pengalaman Pengguna</li>
          </ul>
        </ol>
        <li>
          Pengguna dengan ini memberikan persetujuan atas perolehan, penyimpanan,
          penggunaan, pemanfaatan, dan/atau pengungkapan data dan informasi pribadi
          yang Pengguna sampaikan (input) ke dalam Sistem eCLIS, dengan ini
          menyatakan bahwa pemberian persetujuan atas data dan informasi pribadi
          tersebut dilakukan tanpa paksaan, dalam kondisi cakap, dan dengan keadaan
          sadar.
        </li>
        <li>
          Atas persetujuan data dan informasi pribadi yang Pengguna berikan (input),
          Pengguna dengan ini menyatakan seluruh data dan informasi tersebut adalah
          milik eCLIS. Untuk menghindari keragu-raguan, data pribadi Pengguna, baik
          yang dimasukkan ke dalam Sistem eCLIS maupun yang tidak dimasukkan ke dalam
          Sistem eCLIS, adalah milik Pengguna sebagai bagian dari hak pribadi secara
          utuh.
        </li>
        <li>
          Pengguna dengan ini menyatakan data pribadi yang Pengguna sampaikan (input)
          ke dalam Sistem eCLIS adalah asli, autentik, benar, akurat, lengkap, tidak
          menyesatkan, terbaru, dan merupakan hak pribadi Pengguna dan/atau dirinya
          berwenang untuk menyampaikan data pribadi Pengguna untuk menyampaikannya ke
          dalam Sistem eCLIS.
        </li>
        <li>
          eCLIS melindungi segala informasi yang diberikan Pengguna pada saat
          Pengguna menggunakan seluruh layanan eCLIS, termasuk menjaga rahasia,
          keutuhan, dan ketersediaan data pribadi yang dikelolanya.
        </li>
        <li>
          eCLIS berhak dari waktu ke waktu meminta authentifikasi dan pemukhtahiran
          data pribadi Pengguna, sehingga data dan informasi Pengguna akurat,
          lengkap, dan terbaru, termasuk memberhentikan Layanan sementara dan/atau
          memberhentikan Layanan tetap, dalam hal Pengguna belum melakukan
          authentifikasi dan pemukhtahiran data pribadi Pengguna.
        </li>
        <li>
          Pengguna berhak dari waktu ke waktu melakukan pemukhtahiran data pribadi
          Pengguna.
        </li>
      </ol>

      <br />
      <h4>Penggunaan, Penyimpanan, Pemanfaatan, dan Pengolahan Data</h4>
      <ol>
        <li>
          eCLIS berhak menggunakan data dan informasi pribadi Pengguna demi
          meningkatkan mutu dan pelayanan di eCLIS.
        </li>
        <li>
          eCLIS berhak menggunakan data dan informasi Pengguna, termasuk data
          dan/atau informasi yang dikembangkan namun tidak terkait dengan data
          pribadi Pengguna, sehingga Pengguna tidak teridentifikasi secara individu.
        </li>
        <li>
          eCLIS tidak menggunakan data dan informasi pribadi Pengguna, untuk
          diproses, dikembangkan, dikerjasamakan, dengan pihak ketiga untuk tujuan
          komersil.
        </li>
        <li>
          eCLIS berhak menggunakan, memanfaatkan, dan mengungkapkan data dan
          informasi pribadi Pengguna berdasarkan persetujuan Pengguna, yang telah
          Pengguna berikan pada saat perolehan data pribadi, kecuali terjadi kondisi
          sebagai berikut, antara lain:
        </li>
        <ol>
          <li type="a">
            diwajibkan dan/atau diminta oleh institusi yang berwenang berdasarkan
            ketentuan hukum yang berlaku, somasi, perintah resmi dari Pengadilan,
            dan/atau perintah resmi dari instansi atau aparat yang bersangkutan,
            termasuk namun tidak terbatas pada perselisihan, penyelidikan,
            penyidikan, proses hukum dan proses penyelesaian sengketa antara eCLIS
            dengan Pengguna, antar-Pengguna, dan Pengguna dengan pihak lainnya;
          </li>
          <li type="a">
            diwajibkan dan/atau diminta oleh institusi perbankan, keuangan yang
            berwenang, dan/atau pihak ketiga berdasarkan permintaan resmi dan
            ketentuan hukum yang berlaku;
          </li>
          <li type="a">
            dalam keadaan darurat yang mengancam kehidupan, kesehatan atau
            keselamatan seseorang;
          </li>
          <li type="a">
            bila diperlukan untuk tujuan yang jelas untuk kepentingan Pengguna dan
            persetujuan pemilik data pribadi (Pengguna) tidak dapat diperoleh tepat
            waktu; atau
          </li>
          <li type="a">yang mungkin diperlukan atau diizinkan oleh hukum.</li>
        </ol>
        <li>
          eCLIS berhak menggunakan, menyimpan, memanfaatkan, dan mengungkapkan data
          pribadi berdasarkan persetujuan pemilik data pribadi (Pengguna) yang telah
          Pengguna berikan pada saat perolehan data pribadi untuk:
        </li>
        <ol>
          <li type="a">
            menyediakan Layanan dan/atau Penawaran Pihak Ketiga, misalnya:
          </li>
          <ul>
            <li>
              untuk keperluan yang terkait dengan pembayaran, penagihan, aktivasi,
              penyediaan, pemeliharaan, dukungan, pemecahan masalah, penyelesaian
              sengketa, penonaktifan, penggantian, peningkatan atau pembaharuan
              Layanan;
            </li>
            <li>
              untuk lebih memahami, menganalisis, dan memprediksi preferensi dan
              minat Pengguna, dan menggabungkan informasi tersebut dengan informasi
              lain untuk menyesuaikan pengalaman Pengguna;
            </li>
            <li>
              untuk memastikan Layanan berfungsi secara teknis sebagaimana dimaksud
              dan untuk membantu mengidentifikasi dan memecahkan masalah;
            </li>
            <li>
              untuk memfasilitasi akses Pengguna dan penggunaan Layanan dan / atau
              Penawaran Pihak Ketiga;
            </li>
            <li>
              untuk mengirim Penawaran berlangganan, pesan dan posting dalam Layanan
              atas nama administrator Saluran atau Akun Resmi;
            </li>
            <li>
              untuk menggabungkan informasi tersebut dengan informasi yang diperoleh
              dari sumber lain (termasuk Penawaran Pihak Ketiga) sehubungan dengan
              penyediaan Layanan;
            </li>
            <li>
              untuk memenuhi atau memberlakukan Pemberitahuan yang berlaku untuk
              Layanan;
            </li>
            <li>untuk mengelola atau menanggapi pertanyaan Pengguna;</li>
          </ul>
          <li type="a">
            bmengembangkan Layanan baru dan meningkatkan Layanan yang ada dan
            memberikan Pengguna informasi tentang konten pihak ketiga atau produk
            terkait, layanan dan perangkat lunak termasuk untuk berkomunikasi dengan
            Pengguna tentang berbagai cara yang mereka gunakan, misalnya untuk
            menyediakan atau mengirim kepada Pengguna:
          </li>
          <ul>
            <li>
              peningkatan atau pembaruan, atau pemberitahuan tentang peningkatan atau
              pembaruan, dari Layanan atau konten pihak ketiga atau produk, layanan
              dan perangkat lunak terkait;
            </li>
            <li>pemberitahuan promosi, kontes, penawaran, dan acara mendatang;</li>
            <li>
              informasi pribadi, survei, materi pemasaran, iklan atau konten yang
              disesuaikan;
            </li>
          </ul>
          <li type="a">
            mengumpulkan, menggunakan dan mengungkapkan pengidentifikasi iklan,
            termasuk yang disediakan oleh platform perangkat lunak lain, atau
            pengenal serupa, untuk memfasilitasi eCLIS dalam melakukan analitik atau
            penyediaan konten promosi atau informasi lain yang mungkin relevan bagi
            Anda;
          </li>
          <li type="a">
            mengelola dan mengembangkan bisnis dan operasional eCLIS, misalnya:
          </li>
          <ul>
            <li>
              untuk mendeteksi, memantau, menyelidiki, mengurangi atau mencegah
              penipuan dan masalah teknis atau keamanan atau untuk melindungi
              properti dari eCLIS;
            </li>
            <li>
              untuk memungkinkan kelangsungan bisnis dan operasi pemulihan bencana;
            </li>
            <li>
              untuk mendapatkan layanan hukum, untuk mencari nasihat hukum dan / atau
              untuk menegakkan hak hukum eCLIS atau hak hukum dari anggota lain dari
              eCLIS;
            </li>
            <li>untuk keperluan statistik;</li>
          </ul>
          <li type="a">
            memenuhi persyaratan hukum dan peraturan dan untuk menanggapi situasi
            darurat, misalnya:
          </li>
          <ul>
            <li>
              untuk menanggapi perintah pengadilan, somasi atau permintaan yang sah
              atau penegakan hukum lainnya atau proses hukum;
            </li>
            <li>
              untuk memberikan bantuan darurat dalam situasi yang dapat mengancam
              kehidupan atau keselamatan fisik Anda atau orang lain; atau
            </li>
          </ul>
          <li type="a">
            Kepentingan internal eCLIS untuk pengembangan bisnis, produk, mutu, dan
            pelayanan termasuk kegiatan promosi, studi, riset, dan kerja sama eCLIS
            dengan pihak ketiga lainnya;
          </li>
          <li type="a">
            Kepentingan eCLIS untuk pengembangan bisnis, produk, mutu, dan pelayanan
            termasuk kegiatan promosi, studi, riset, dan kerja sama eCLIS dengan
            pihak ketiga lainnya;
          </li>
          <li type="a">
            Tujuan lain yang disampaikan dari waktu ke waktu oleh eCLIS kepada
            Pengguna, serta diizinkan atau diwajibkan oleh hukum dan peraturan yang
            berlaku.
          </li>
        </ol>

        <li>
          eCLIS berhak menyimpan data dan informasi pribadi Pengguna untuk memenuhi
          tujuan Layanan, tujuan Kebijakan Privasi ini, tujuan peraturan
          perundang-undangan dan hukum yang berlaku, termasuk memproses, mentransfer,
          dan/atau menyimpan data dan informasi para Pengguna, baik di dalam wilayah
          Republik Indonesia maupun di luar wilayah Republik Indonesia, dengan
          memperhatikan Regulasi, termasuk peraturan perundang-undangan dan kebijakan
          Privasi di negara mana data dan informasi para Pengguna diproses,
          ditransfer, dan/atau disimpan.
        </li>
        <li>
          eCLIS dan pihak ketiga yang bekerja sama dengan eCLIS, dalam menyediakan
          Layanan berhak memanfaatkan dan mengolah data dan informasi pribadi
          Pengguna untuk memenuhi tujuan Layanan, tujuan Kebijakan Privasi ini,
          tujuan peraturan perundang-undangan dan hukum yang berlaku. Pemanfaatan dan
          pengolahan data tersebut dicakup oleh perjanjian dan memuat klausul
          kerahasiaan yang mengikat para pihak.{' '}
        </li>
        <li>
          eCLIS dalam pemanfaatan dan pengolahan data, termasuk transmisi data,
          sesuai dengan tujuan Kebijakan Privasi ini akan menerapkan keamanan
          sewajarnya, sesuai dengan peraturan perundang-undangan dan hukum yang
          berlaku, termasuk memberikan enkripsi pada pemanfaatan dan pengolahan data
          tersebut.
        </li>
      </ol>

      <br />
      <h4>Penghapusan Data</h4>
      <ol>
        <li>
          Pengguna dapat menarik persetujuan yang Pengguna berikan terkait dengan
          penggunaan data dan informasi pribadi Pengguna, termasuk berhenti
          menggunakan, mengakses Layanan, dan/atau menutup akun. Pengguna dengan ini
          menyatakan bahwa Pengguna memahami konsekuensi dari penarikan persetujuan
          Pengguna, termasuk namun tidak terbatas pada tidak lagi dapat menikmati
          Layanan.
        </li>
        <li>
          eCLIS atas permintaan Pengguna dapat mengumpulkan data dan informasi
          Pengguna dibuat tidak dikenali.
        </li>
        <li>
          eCLIS akan menghapus data Pribadi Pengguna atas permintaan Pengguna
          berdasarkan penetapan Pengadilan, sesuai peraturan perundang-undangan.
        </li>
      </ol>

      <br />
      <h4>Pembatasan Tanggung Jawab</h4>
      <ol>
        <li>
          Pengguna bertanggung jawab atas keamanan dan mitigasi pelanggaran atas akun
          eCLIS Pengguna sendiri, seperti membatasi akses selain daripada Pengguna,
          membuat kata sandi yang kuat dan menjaga kata sandi.
        </li>
        <li>
          eCLIS tidak bertanggung jawab atas pertukaran dan pemberian data dan
          informasi pribadi Pengguna yang dilakukan sendiri oleh Pengguna, termasuk
          yang dilakukan antar-Pengguna.
        </li>
        <li>
          eCLIS bertanggung jawab atas Sistem eCLIS, termasuk perlindungan dan
          pengamanan rahasia data pribadi, termasuk memberitahukan Pengguna dalam hal
          terjadi kegagalan dalam perlindungan data pribadi melalui
          sekurang-kurangnya e-mail Pengguna yang terdaftar pada Sistem eCLIS dan
          melaporkan kepada aparat penegak hukum atau Instansi Pengawas dan Pengatur
          Sektor terkait.
        </li>
        <li>
          eCLIS tidak bertanggung jawab atas keaslian, keautentikan, kebenaran,
          keakuratan, kelengkapan data pribadi yang dimasukkan oleh Pengguna ke dalam
          Sistem eCLIS.
        </li>
        <li>
          Dengan memberikan persetujuan ini, Pengguna melepaskan hak atas klaim,
          kerugian, tuntutan, dan gugatan yang mungkin terjadi atas perolehan,
          penyimpanan,penggunaan, pemanfaatan, dan/atau penggungkapan data, termasuk
          data pribadi, dalam Sistem eCLIS.
        </li>
      </ol>

      <br />
      <h4>Persetujuan</h4>
      <p>
        Dengan menggunakan Platform eCLIS, Pengguna dengan ini menyetujui kebijakan
        privasi dan setuju dengan ketentuan-ketentuannya. Kebijakan Privasi ini
        terakhir diperbaharui pada <b>tanggal 25 November 2019</b> Kebijakan Privasi
        ini dapat diperbaharui atau diubah, dan perubahan tersebut akan dimuat di
        halaman ini.
      </p>

      <br />
      <h4>Informasi Lebih Lanjut</h4>
      <p>
        Terkait Kebijakan Privasi ini Pengguna dapat menghubungi Pengelola eCLIS
        melalui email customercare@eclis.id
      </p>
    </div>
  )
}
export default PrivacyPolicy
