import React from 'react'
import styled from 'styled-components'
import color from '../assets/css/colors.scss'
import { rgba } from 'polished'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

library.add(fas)
library.add(far)
library.add(fab)

// ARROW ICON
export const icon_chevronUp = (
  <FontAwesomeIcon icon={['fas', 'chevron-up']} className="fa-fw" />
)
export const icon_chevronDown = (
  <FontAwesomeIcon icon={['fas', 'chevron-down']} className="fa-fw" />
)
export const icon_chevronRight = (
  <FontAwesomeIcon icon={['fas', 'chevron-right']} className="fa-fw" />
)
export const icon_chevronLeft = (
  <FontAwesomeIcon icon={['fas', 'chevron-left']} className="fa-fw" />
)
export const icon_caretDown = (
  <FontAwesomeIcon icon={['fas', 'caret-down']} className="fa-fw" />
)
export const icon_caretUp = (
  <FontAwesomeIcon icon={['fas', 'caret-up']} className="fa-fw" />
)
export const icon_longArrowDown = (
  <FontAwesomeIcon icon={['fas', 'long-arrow-alt-down']} className="fa-fw" />
)
export const icon_arrowCircle_up = (
  <FontAwesomeIcon icon={['fas', 'chevron-circle-up']} className="fa-fw" />
)
export const icon_arrow_tail_left = (
  <FontAwesomeIcon icon={['fas', 'arrow-left']} className="fa-fw" />
)
export const icon_external_link = (
  <FontAwesomeIcon icon={['fas', 'external-link-alt']} className="fa-fw" />
)

// DASHBOARD ICON
export const icon_institute = (
  <FontAwesomeIcon icon={['far', 'building']} className="fa-fw" />
)
export const icon_mail = (
  <FontAwesomeIcon icon={['far', 'envelope']} className="fa-fw" />
)
export const icon_clock = (
  <FontAwesomeIcon icon={['far', 'clock']} className="fa-fw" />
)
export const icon_setting = (
  <FontAwesomeIcon icon={['fas', 'cog']} className="fa-fw" />
)
export const icon_overview = (
  <FontAwesomeIcon icon={['far', 'address-card']} className="fa-fw" />
)
export const icon_profile = (
  <FontAwesomeIcon icon={['far', 'user-circle']} className="fa-fw" />
)
export const icon_question_regular = (
  <FontAwesomeIcon icon={['far', 'question-circle']} className="fa-fw" />
)
export const icon_trash = (
  <FontAwesomeIcon icon={['far', 'trash-alt']} className="fa-fw" />
)

// STATUS ICON
export const icon_ok = (
  <FontAwesomeIcon icon={['fas', 'check-circle']} className="fa-fw" />
)
export const icon_warning_triangle = (
  <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} />
)

// MODEL VIEW
export const icon_normal_view = <FontAwesomeIcon icon={['fas', 'bars']} />
export const icon_side_view = <FontAwesomeIcon icon={['fas', 'columns']} />
export const icon_structured_view = <FontAwesomeIcon icon={['fas', 'list']} />
export const icon_updated_view = <FontAwesomeIcon icon={['fas', 'tasks']} />
export const icon_raw_view = <FontAwesomeIcon icon={['far', 'file-excel']} />
export const icon_bug = <FontAwesomeIcon icon={['fas', 'bug']} className="fa-fw" />
export const icon_edit = <FontAwesomeIcon icon={['fas', 'pen']} className="fa-fw" />
export const icon_integration = (
  <FontAwesomeIcon icon={['fas', 'sitemap']} className="fa-fw" />
)

// SEARCH RESULT VIEW
export const icon_sitemap = (
  <FontAwesomeIcon icon={['fas', 'sitemap']} className="fa-fw" />
)
export const icon_th_list = (
  <FontAwesomeIcon icon={['fas', 'th-list']} className="fa-fw" />
)

// SORT TABLE ICON
export const icon_sort_up = (
  <FontAwesomeIcon icon={['fas', 'sort-up']} className="fa-fw" />
)
export const icon_sort_down = (
  <FontAwesomeIcon icon={['fas', 'sort-down']} className="fa-fw" />
)
export const icon_sort_init = (
  <FontAwesomeIcon icon={['fas', 'sort']} className="fa-fw" />
)

export const icon_list = (
  <FontAwesomeIcon icon={['far', 'list-alt']} className="fa-fw" />
)
export const icon_download_file = (
  <FontAwesomeIcon icon={['fas', 'file-download']} className="fa-fw" />
)
export const icon_pdf = (
  <FontAwesomeIcon icon={['fas', 'file-pdf']} className="fa-fw" />
)
export const icon_search = (
  <FontAwesomeIcon icon={['fas', 'search']} className="fa-fw" />
)
export const icon_check = (
  <FontAwesomeIcon icon={['fas', 'check']} className="fa-fw" />
)
export const icon_check_circle = (
  <FontAwesomeIcon icon={['fas', 'check-circle']} className="fa-fw" />
)
export const icon_hand_money = (
  <FontAwesomeIcon icon={['fas', 'hand-holding-usd']} className="fa-fw" />
)
export const icon_page = <FontAwesomeIcon icon={['far', 'copy']} className="fa-fw" />
export const icon_plus = <FontAwesomeIcon icon={['fas', 'plus']} className="fa-fw" />
export const icon_times = (
  <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw" />
)
export const icon_times_circle = (
  <FontAwesomeIcon icon={['fas', 'times-circle']} className="fa-fw" />
)
export const icon_lock = <FontAwesomeIcon icon={['fas', 'lock']} className="fa-fw" />
export const icon_unlock = (
  <FontAwesomeIcon icon={['fas', 'unlock']} className="fa-fw" />
)
export const icon_shop = (
  <FontAwesomeIcon icon={['fas', 'shopping-cart']} className="fa-fw" />
)
export const icon_paper = (
  <FontAwesomeIcon icon={['fas', 'file']} className="fa-fw" />
)
export const icon_cursor = (
  <FontAwesomeIcon icon={['fas', 'mouse-pointer']} className="fa-fw" />
)
export const icon_question = (
  <FontAwesomeIcon icon={['fas', 'question']} className="fa-fw" />
)
export const icon_filter = (
  <FontAwesomeIcon icon={['fas', 'filter']} className="fa-fw" />
)
export const icon_book = <FontAwesomeIcon icon={['fas', 'book']} className="fa-fw" />
export const icon_book_open = (
  <FontAwesomeIcon icon={['fas', 'book-open']} className="fa-fw" />
)

export const icon_chat = (
  <FontAwesomeIcon icon={['far', 'comment-dots']} className="fa-fw" />
)
export const icon_chat_group_fr = (
  <FontAwesomeIcon icon={['far', 'comments']} className="fa-fw" />
)
export const icon_comment = (
  <FontAwesomeIcon icon={['far', 'comment']} className="fa-fw" />
)

export const icon_evaluate = (
  <FontAwesomeIcon icon={['far', 'edit']} className="fa-fw" />
)
export const icon_bookmark = (
  <FontAwesomeIcon icon={['far', 'bookmark']} className="fa-fw" />
)
export const icon_bookmark_solid = (
  <FontAwesomeIcon icon={['fas', 'bookmark']} className="fa-fw" />
)
export const icon_star = <FontAwesomeIcon icon={['far', 'star']} className="fa-fw" />
export const icon_star_solid = (
  <FontAwesomeIcon icon={['fas', 'star']} className="fa-fw" />
)

export const icon_question_fr = (
  <FontAwesomeIcon icon={['far', 'question-circle']} className="fa-fw" />
)
export const icon_hint = (
  <FontAwesomeIcon icon={['fas', 'question-circle']} className="fa-fw" />
)
export const icon_pass_visible = (
  <FontAwesomeIcon icon={['fas', 'eye']} className="fa-fw" />
)
export const icon_pass_invisible = (
  <FontAwesomeIcon icon={['fas', 'eye-slash']} className="fa-fw" />
)

export const icon_home = <FontAwesomeIcon icon={['fas', 'home']} className="fa-fw" />
export const icon_nav_toggle = (
  <FontAwesomeIcon icon={['fas', 'bars']} className="fa-fw" />
)
export const icon_copy = <FontAwesomeIcon icon={['far', 'copy']} className="fa-fw" />

// SOCIAL MEDIA SHARE
export const icon_share = (
  <FontAwesomeIcon icon={['fa', 'share']} className="fa-fw" />
)
export const icon_share_alt = (
  <FontAwesomeIcon icon={['fa', 'share-alt']} className="fa-fw" />
)
export const icon_fb = (
  <FontAwesomeIcon icon={['fab', 'facebook-f']} className="fa-fw" />
)
export const icon_twitter = (
  <FontAwesomeIcon icon={['fab', 'twitter']} className="fa-fw" />
)
export const icon_linkedin = (
  <FontAwesomeIcon icon={['fab', 'linkedin']} className="fa-fw" />
)
export const icon_ig = (
  <FontAwesomeIcon icon={['fab', 'instagram']} className="fa-fw" />
)
export const icon_whatsapp = (
  <FontAwesomeIcon icon={['fab', 'whatsapp']} className="fa-fw" />
)
export const icon_like_active = (
  <FontAwesomeIcon icon={['fas', 'thumbs-up']} className="fa-fw" />
)
export const icon_like_inactive = (
  <FontAwesomeIcon icon={['far', 'thumbs-up']} className="fa-fw" />
)
export const icon_dislike_active = (
  <FontAwesomeIcon icon={['fas', 'thumbs-down']} className="fa-fw" />
)
export const icon_dislike_inactive = (
  <FontAwesomeIcon icon={['far', 'thumbs-down']} className="fa-fw" />
)

const Icon = ({ icon, size, initColor, hoverColor, onClick }) => {
  return (
    <Wrapper
      onClick={onClick}
      size={size}
      initColor={initColor}
      hoverColor={hoverColor}
    >
      {icon}
    </Wrapper>
  )
}
export default Icon

const Wrapper = styled.div`
  cursor: pointer;

  svg {
    font-size: ${(props) => props.size || '16px'};
    color: ${(props) => props.initColor || color.neutral};
    background-color: ${rgba(color.primary, 0)};
    border-radius: 25px;
    box-shadow: 0px 0px 0px 0px ${rgba(color.primary, 0.1)};
    transition: all 0.05s ease-in-out;
  }

  &:hover {
    svg {
      color: ${(props) => props.hoverColor || color.primary};
      background-color: ${(props) =>
        props.hoverColor ? rgba(props.hoverColor, 0.1) : rgba(color.primary, 0.1)};
      box-shadow: 0px 0px 0px 10px
        ${(props) =>
          props.hoverColor ? rgba(props.hoverColor, 0.1) : rgba(color.primary, 0.1)};
    }
  }
`

export const Attachment = ({ name, index, remove }) => {
  return (
    <Attach>
      <div className="icon"> {icon_paper} </div>
      <div className="name"> {name} </div>
      <div className="remove" onClick={() => remove(index)}>
        {icon_times}
      </div>
    </Attach>
  )
}
const Attach = styled.div`
  margin-bottom: 0.5rem;

  .icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    margin-right: 0.5rem;

    svg {
      font-size: 16px;
      color: ${color.primary};
    }
  }

  .name {
    display: inline-block;
    vertical-align: middle;
    font-weight: 600;
    font-size: 16px;
  }

  .remove {
    display: inline-block;
    vertical-align: bottom;
    margin-left: 0.5rem;
    color: ${color.danger};
    cursor: pointer;
  }
`
