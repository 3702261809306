/* eslint-disable react-hooks/exhaustive-deps */
import React, { lazy, Suspense, useEffect, useLayoutEffect } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import '@fortawesome/fontawesome-svg-core/styles.css'
import 'bootstrap/dist/css/bootstrap.css'
import './assets/css/document.scss'
import './assets/css/global.scss'
import 'react-toastify/dist/ReactToastify.min.css'
import 'react-circular-progressbar/dist/styles.css'
import 'ag-grid-community/dist/styles/ag-grid.css'
import { connect } from 'react-redux'
import {
  userSession,
  setListOfAds,
  fetchDomainAssets,
  checkIsSubDomain,
  setUserInformations,
  authLogout,
} from './redux/actions'
import styled, { ThemeProvider } from 'styled-components'
import { ToastContainer } from 'react-toastify'
import Cookies from 'js-cookie'
import PublicRoute from 'routes/PublicRoute'
import { useGoogleAnalytics } from 'hooks'

import RouterScrollToTop from 'components/RouterScrollToTop'
import GlobalErrorBoundaries from 'components/ErrorBoundaries/GlobalErrorBoundaries'
import LoadingScreen from 'components/LoadingScreen'
import Navbar from 'components/Navbar'
import ComponentTest from 'page/test/ComponentTest'
import NotFound from 'page/404'
import GlobalErrorFallback from 'components/ErrorBoundaries/GlobalErrorFallback'

import { retry } from 'helper'
import theme from 'Constants/theme'

import 'helper/firebase/initializeFirebase'

const lazyWithRetry = (fn) => lazy(() => retry(() => fn()))

const NewHomepage = lazyWithRetry(() => import('page/NewHomepage'))
const Homepage = lazyWithRetry(() => import('page/Homepage'))
const SearchResult = lazyWithRetry(() => import('page/SearchResult'))
const PasalTerkait = lazyWithRetry(() => import('page/PasalTerkait'))
const ProdukMesinPencarian = lazyWithRetry(() =>
  import('page/Produk/ProdukMesinPencarian')
)
const ProdukHimpunanCatatan = lazyWithRetry(() =>
  import('page/Produk/ProdukHimpunanCatatan')
)
const PenggunaIndividu = lazyWithRetry(() => import('page/Jasa/PenggunaIndividu'))
const Organisasi = lazyWithRetry(() => import('page/Jasa/Organisasi'))
const MitraDaerah = lazyWithRetry(() => import('page/MitraDaerah'))
const MitraUniversitas = lazyWithRetry(() => import('page/MitraUniversitas'))
const PrivacyPolicy = lazyWithRetry(() => import('page/PrivacyPolicy'))
const PusatInformasi = lazyWithRetry(() => import('page/PusatInformasi'))
const View = lazyWithRetry(() => import('page/View'))
const Mitra = lazyWithRetry(() => import('page/Mitra'))

// Authentication
const Login = lazyWithRetry(() => import('page/Login'))
const Register = lazyWithRetry(() => import('page/Register'))
const ForgotPassword = lazyWithRetry(() => import('page/ForgotPassword'))

// Fitur
const UserDashboard = lazyWithRetry(() => import('page/UserDashboard'))
const Kodifikasi = lazyWithRetry(() => import('page/Kodifikasi'))
const Kompilasi = lazyWithRetry(() => import('page/Kompilasi'))
const DefinisiHukum = lazyWithRetry(() => import('page/DefinisiHukum'))
const HimpunanCatatan = lazyWithRetry(() => import('page/HimpunanCatatan'))
const AnalisisEvaluasi = lazyWithRetry(() => import('page/AnalisisEvaluasi'))
const WorkingGroup = lazyWithRetry(() => import('page/WorkingGroup'))
const PusatData = lazyWithRetry(() => import('page/PusatData'))
const RancanganPeraturan = lazyWithRetry(() => import('page/RancanganPeraturan'))

// Payment
const Payment = lazyWithRetry(() => import('page/Payment'))
const UpgradeMembership = lazyWithRetry(() => import('page/Upgrademembership'))

// Karir
const Karir = lazyWithRetry(() => import('page/Karir'))

const App = ({
  isLogin,
  userSession,
  setListOfAds,
  fetchDomainAssets,
  checkIsSubDomain,
  setUserInformations,
  userInfo,
  authLogout,
}) => {
  useGoogleAnalytics()
  // useGoogleAnalyticsNew()

  useLayoutEffect(() => {
    // check is subDomain
    checkIsSubDomain()

    // fetch assets related to the domain
    fetchDomainAssets()

    // fetch user information
    // penting supaya request jwt ketika return 401 tidak redundant
    setUserInformations(isLogin)
  }, [isLogin])

  // hanya jalan SESUDAH user information ada di redux
  useLayoutEffect(() => {
    if (isLogin && userInfo.profile) {
      setListOfAds(isLogin, userInfo.membership)
    }
  }, [userInfo])

  useEffect(() => {
    if (process.env.REACT_APP_IS_MAINTAINANCE === 'false') {
      if (
        isLogin &&
        (!Cookies.get('access_jwt') || Cookies.get('access_jwt') === 'undefined')
      ) {
        userSession('logout', { saveLastUrl: true })
      }

      if (Cookies.get('access_jwt') && Cookies.get('refresh_jwt')) {
        userSession('login')
      }
    } else {
      authLogout()
    }
  }, [])
  return (
    <ThemeProvider theme={theme}>
      <Div>
        <Router>
          <Suspense fallback={<LoadingScreen />}>
            <GlobalErrorBoundaries>
              <RouterScrollToTop />

              <Navbar />

              <ToastContainer />

              <Switch>
                <Route exact path="/" component={Homepage} />
                <Route exact path="/new" component={NewHomepage} />
                <Route path="/view" component={View} />

                <PublicRoute
                  exact
                  path="/privacy-policy"
                  component={PrivacyPolicy}
                />
                <PublicRoute exact path="/mitra-daerah" component={MitraDaerah} />
                <PublicRoute exact path="/mitra-universitas" component={MitraUniversitas} />
                <PublicRoute
                  exact
                  path="/jasa/pengguna-individu"
                  component={PenggunaIndividu}
                />
                <PublicRoute exact path="/jasa/organisasi" component={Organisasi} />
                <PublicRoute
                  exact
                  path="/produk/himpunan-catatan"
                  component={ProdukHimpunanCatatan}
                />
                <PublicRoute
                  exact
                  path="/produk/mesin-pencarian"
                  component={ProdukMesinPencarian}
                />

                <Route path="/register" component={Register} />
                <Route path="/forgot" component={ForgotPassword} />
                <Route path="/login" component={Login} />

                <Route exact path="/upgrade" component={UpgradeMembership} />

                <Route path="/search" component={SearchResult} />
                <Route path="/pasal-terkait" component={PasalTerkait} />
                <Route path="/mitra" component={Mitra} />

                <Route path="/definisi" component={DefinisiHukum} />
                <Route path="/notes" component={HimpunanCatatan} />
                <Route path="/kodifikasi" component={Kodifikasi} />
                <Route path="/kompilasi" component={Kompilasi} />
                <Route path="/evaluasi" component={AnalisisEvaluasi} />
                <Route path="/working-group" component={WorkingGroup} />
                <Route path="/pusat-data" component={PusatData} />
                <Route path="/rancangan-peraturan" component={RancanganPeraturan} />

                <Route path="/dashboard" component={UserDashboard} />
                <Route path="/payment" component={Payment} />
                <Route path="/karir" component={Karir} />

                <Route path="/pusat-informasi" component={PusatInformasi} />
                <Route
                  exact
                  path="/component-test"
                  component={ComponentTest}
                  auth={isLogin}
                />

                <Route path="/404" component={NotFound} />
                <Route path="/global-error" component={GlobalErrorFallback} />

                <Redirect to="/404" />
              </Switch>
            </GlobalErrorBoundaries>
          </Suspense>
        </Router>
      </Div>
    </ThemeProvider>
  )
}

const mapStateToProps = (state) => {
  return {
    isLogin: state.isLogin,
    hasExclusiveFeature: state.hasExclusiveFeature,
    domainAssets: state.domainAssets,
    isSubDomain: state.isSubDomain,
    userInfo: state.userInfo,
  }
}

export default connect(mapStateToProps, {
  userSession,
  setListOfAds,
  fetchDomainAssets,
  checkIsSubDomain,
  setUserInformations,
  authLogout,
})(App)

const Div = styled.div`
  min-height: calc(100vh - 100px);
  height: 100%;
  position: relative;
`
