import React, { useRef, useMemo } from 'react'
import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { rgba } from 'polished'
import { userSession, authLogout } from 'redux/actions'
import { useDetectClickOutsideElement, useDidUpdateEffect } from 'hooks'
import Cookies from 'js-cookie'
import color from 'assets/css/colors.scss'
import { useMediaQuery } from 'react-responsive'
import { MOBILE_SCREEN } from 'Constants/display'
import DropdownWithArrow from 'components/DropdownWithArrow'

const DropdownAccount = ({ setOpen, userInfo, authLogout }) => {
  const location = useLocation()
  const accountRef = useRef()
  useDetectClickOutsideElement(accountRef, () => setOpen(false))

  useDidUpdateEffect(() => {
    setOpen(false)
  }, [location.pathname])

  const logOut = () => {
    Cookies.remove('lastVisit')
    authLogout()
  }


  const isMobile = useMediaQuery({ query: MOBILE_SCREEN })
  const openInNewTab = useMemo(() => {
    return isMobile ? "" : "_blank"
  }, [isMobile])

  return (
    <DropdownArrowStyled minWidth="260px">
      <div ref={accountRef}>
        <Detail>
          <Name>{userInfo.profile.name}</Name>
          <Member>{userInfo.profile.membership.name}</Member>
        </Detail>

        {/* {userInfo.isAdmin && (
          <div
            className="menu_list"
            onClick={() =>
              isMobile ?
                window.location.replace(`${process.env.REACT_APP_ADMIN_URL}/dashboard`)
                :
                window.open(`${process.env.REACT_APP_ADMIN_URL}/dashboard`)
            }
          >
            Admin Dashboard
          </div>
        )} */}

        <div className="menu_list">
          <Link to="/dashboard"> Dashboard </Link>
        </div>

        {/* <div className="menu_list">
          <a
            target={openInNewTab}
            rel="noopener noreferrer"
            href="https://academic.eclis.id"
          >
            Academic Eclis
          </a>
        </div> */}

        <div className="menu_list" onClick={logOut}>
          Keluar
        </div>
      </div>
    </DropdownArrowStyled>
  )
}

const mapStateToProps = (state) => {
  return {
    isLogin: state.isLogin,
    userInfo: state.userInfo,
  }
}
export default connect(mapStateToProps, { userSession, authLogout })(DropdownAccount)

const DropdownArrowStyled = styled(DropdownWithArrow)`
  top: 60px;
  right: -30px;
  z-index: 10000;

  .menu_list {
    font-weight: 600;
    font-size: 1rem !important;
    display: block;
    text-decoration: none !important;
    cursor: pointer;
    padding-block-start: 0.75rem;
    padding-block-end: 0.75rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    transition: all 0.2s ease-in-out;

    a {
      display: block;
      text-decoration: none !important;
      color: ${color.black};
    }

    &:hover {
      background-color: ${rgba('#24B6FF', 0.1)}; /* #24B6FF */
      text-decoration: none;
    }
  }
`

const Detail = styled.li`
  border-bottom: 1px solid #ededed;
  padding-bottom: 0.5rem;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
`

const Name = styled.div`
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: -6px;
`

const Member = styled.div`
  font-weight: 400;
  font-size: 13px;
  color: ${color.primary} !important;
`
