/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { useLocation, useHistory } from 'react-router-dom'
import { useQueryParse } from 'hooks'
import { useMediaQuery } from 'react-responsive'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MOBILE_SCREEN } from 'Constants/display'
import color from 'assets/css/colors.scss'
import { MIN_QUERY_SEARCH_LENGTH } from 'Constants/global'
import NavSearchType from './NavSearchType'

const SearchType = ['eClis', 'Kompilasi', 'Definisi Hukum', 'Himpunan Catatan']

const NavSearch = () => {
  const [value, setValue] = useState('')

  const isMobile = useMediaQuery({ query: MOBILE_SCREEN })
  const query = useQueryParse()
  const location = useLocation()
  const history = useHistory()
  const [searchFocus, setSearchFocus] = useState('')

  useEffect(() => {
    if (location.pathname === '/search') {
      setValue(query.search)
    }
  }, [location.pathname])

  useEffect(() => {
    switch (location.pathname) {
      case '/definisi':
        setSearchFocus(SearchType[2])
        break
      case '/notes':
        setSearchFocus(SearchType[3])
        break
      case '/kompilasi':
        setSearchFocus(SearchType[1])
        break
      default:
        setSearchFocus(SearchType[0])
        break
    }
  }, [location.pathname])

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault()

      if (value.length >= MIN_QUERY_SEARCH_LENGTH) {
        let finalReplace = ''

        switch (searchFocus) {
          case SearchType[0]:
            finalReplace = `/search?search=${value}&sort=hierarki`
            break
          case SearchType[1]:
            finalReplace = `/kompilasi?search=${value}`
            break
          case SearchType[2]:
            finalReplace = `/definisi?search=${value}`
            break
          case SearchType[3]:
            finalReplace = `/notes?type=content&scope=all&search=${value}`
            break
          default:
            finalReplace = `/search?search=${value}&sort=hierarki`
        }

        history.replace(finalReplace)
      }
    },
    [value, searchFocus]
  )

  const renderCondition = location.pathname === '/' ? 'none' : 'block'

  return (
    <form
      onSubmit={handleSubmit}
      autoComplete="off"
      style={{ display: renderCondition }}
    >
      <Wrapper style={{ width: isMobile ? '56vw' : '400px' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Search
            name="search"
            type="text"
            placeholder="Saya mencari..."
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
          {!isMobile && searchFocus && (
            <NavSearchType
              value={value}
              searchType={SearchType}
              searchFocus={searchFocus}
              setSearchFocus={setSearchFocus}
              setValue={setValue}
            />
          )}
        </div>
        <FontAwesomeIcon
          icon={['fas', 'search']}
          style={{
            fontSize: '15px',
            position: 'absolute',
            left: '16px',
            top: '11px',
            color: '#8ac1f4',
          }}
        />
      </Wrapper>
    </form>
  )
}

export default NavSearch

let Search = styled.input`
  font-size: 16px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  border: none;
  background-color: #fff;
  transition: all 0.2s ease-in-out;

  &::placeholder {
    color: ${color.neutralDark};
  }
  &:focus {
    outline: none;
    background-color: #fff;
  }
`

let Wrapper = styled.label`
  height: 40px;
  border-radius: 5px;
  // padding-top: 6px;
  // padding-bottom: 6px;

  // display: flex;
  // flex-direction: row;
  // align-items: center;
  display: grid;
  grid-template-columns: 1fr;
  // justify-content: flex-end;
  padding-left: 40px;
  margin-bottom: 0rem !important;
  position: relative;
  background-color: #fff;
  cursor: text;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #fff;
  }
  &:hover ${Search} {
    background-color: #fff;
  }
`
