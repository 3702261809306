import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

const MobileMenu = ({ hasExclusiveFeature }) => {
  return useMemo(() => {
    return hasExclusiveFeature.map((x, i) => {
      if (x.label === 'Definisi Hukum') {
        return (
          <li key={i}>
            <Link to="/definisi">Definsi Hukum</Link>
          </li>
        )
      }
      if (x.label === 'Kompilasi Hukum') {
        return (
          <li key={i}>
            <Link to="/kompilasi">Kompilasi Peraturan</Link>
          </li>
        )
      }
      if (x.label === 'Kodifikasi Hukum') {
        return (
          <li key={i}>
            <Link to="/kodifikasi">Kodifikasi Hukum</Link>
          </li>
        )
      }
      // if (x.label === 'Konstitusi Indonesia') {
      //   return (
      //     <li key={i}>
      //       <Link to="/konstitusi">Konstitusi Indonesia</Link>
      //     </li>
      //   )
      // }
      if (x.label === 'Himpunan Catatan') {
        return (
          <li key={i}>
            <Link to="/notes">Himpunan Catatan</Link>
          </li>
        )
      }
      if (x.label === 'Evaluasi Peraturan') {
        return (
          <li key={i}>
            <Link to="/evaluasi">Evaluasi Peraturan</Link>
          </li>
        )
      }
      if (x.label === 'Working Group') {
        return (
          <li key={i}>
            <Link to="/working-group">Working Group</Link>
          </li>
        )
      }
      if (x.label === 'Pusat Data') {
        return (
          <li key={i}>
            <Link to="/pusat-data">Pusat Data Peraturan</Link>
          </li>
        )
      }
      return null
    })
  }, [hasExclusiveFeature])
}

const mapStateToProps = (state) => {
  return {
    hasExclusiveFeature: state.hasExclusiveFeature,
  }
}
export default connect(mapStateToProps)(MobileMenu)
