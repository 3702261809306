import React, { useState } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { authLogout } from 'redux/actions'

import { icon_chevronDown } from 'components/FontAwesome'
import DropdownAccount from './DropdownAccount'
import MyProfileAvatar from 'components/MyProfileAvatar'

const NavAccount = ({ isAdmin, userSession, authLogout }) => {
  const [isOpen, setOpen] = useState(false)

  try {
    return (
      <Wrapper>
        <div onClick={() => setOpen(true)}>
          <MyProfileAvatar desktopSize={45} mobileSize={45} />
          <Icon>{icon_chevronDown}</Icon>
        </div>
        {isOpen && <DropdownAccount isAdmin={isAdmin} setOpen={(x) => setOpen(x)} />}
      </Wrapper>
    )
  } catch {
    authLogout()
    return null
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    isLogin: state.isLogin,
  }
}

export default connect(mapStateToProps, { authLogout })(NavAccount)

const Wrapper = styled.div`
  position: relative;
  cursor: pointer;
`
const Icon = styled.div`
  background-color: #fff;
  height: 17px;
  width: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  position: absolute;
  top: 15px;
  left: 34px;

  svg {
    font-size: 9px;
    transition: all 0.1s ease-in-out;
  }
`
