import React, { useState, useEffect } from 'react'
import Avatar from 'react-avatar'
import { useMediaQuery } from 'react-responsive'
import { MOBILE_SCREEN } from 'Constants/display'
import { connect } from 'react-redux'

import Spinner from './Spinner'

const MyProfileAvatar = ({
  mobileSize = 80,
  desktopSize = 200,
  APIFetchUrl,
  userInfo,
}) => {
  const [imageSrc, setImageSrc] = useState(null)
  const [isLoading, setLoading] = useState(true)

  const isMobile = useMediaQuery({ query: MOBILE_SCREEN })

  useEffect(() => {
    if (userInfo.profile) {
      userInfo.profile.img !== null &&
        setImageSrc(`${APIFetchUrl}${userInfo.profile.img}`)
      setLoading(false)
    }
  }, [APIFetchUrl, userInfo.profile])

  if (isLoading) {
    return <Spinner />
  } else {
    if (imageSrc !== null) {
      return (
        <Avatar
          name={userInfo.profile.name}
          maxInitials={1}
          size={isMobile ? mobileSize : desktopSize}
          src={imageSrc}
          color="#FF4080"
          round
        />
      )
    }

    return (
      <Avatar
        name={userInfo.profile.name}
        maxInitials={1}
        size={isMobile ? mobileSize : desktopSize}
        color="#FF4080"
        round
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    APIFetchUrl: state.APIFetchUrl,
    userInfo: state.userInfo,
  }
}
export default connect(mapStateToProps)(MyProfileAvatar)
