import React from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'
import Spinner from 'components/Spinner'

const LoadingModal = () => {
  return (
    <Container>
      <Spinner />
    </Container>
  )
}

export default LoadingModal

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: ${rgba('#ffffff', 0.5)};
`
