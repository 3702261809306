import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import * as Sentry from '@sentry/react'
const SentryRoute = Sentry.withSentryRouting(Route)

// Route access if user DONT HAVE authorization
const PublicRoute = ({
  component: Component,
  isLogin,
  render = (props) => (!isLogin ? <Component {...props} /> : <Redirect to="/" />),
  ...rest
}) => {
  return <SentryRoute {...rest} render={render} />
}

const mapStateToProps = (state) => {
  return {
    isLogin: state.isLogin,
  }
}
export default connect(mapStateToProps)(PublicRoute)
