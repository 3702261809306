import React from 'react'

const TOS = () => {
  return (
    <div>
      <center>
        <h3>Ketentuan Penggunaan eCLIS</h3>
      </center>
      <p>
        PT Sistem Kodifikasi Informasi Hukum Elektronik (selanjutnya disebut eCLIS)
        adalah suatu perseroan terbatas yang salah satu jenis usahanya bergerak di
        bidang jasa portal web yang menyediakan mesin pencarian peraturan (
        <em>regulation search engine</em>), platform Analisis dan Evaluasi Peraturan
        Perundang-Undangan dan Platform Penyusunan Peraturan. eCLIS menjamin keamanan
        dan kenyamanan bagi Pengguna.
      </p>
      <p>
        Aturan Penggunaan ini mengatur penggunaan seluruh layanan yang terdapat pada
        Platform eCLIS yang berlaku terhadap seluruh Pengguna dan terhadap setiap
        Pihak yang menyamaikan permintaan atau informasi kepada eCLIS. Dengan
        mendaftar akun eCLIS dan/atau menggunakan Platform eCLIS, maka Pengguna
        dianggap telah membaca, mengerti, memahami dan menyetujui seluruh isi dalam
        Aturan Penggunaan.
      </p>
      <br />
      <p>
        ATURAN PENGGUNAAN INI MERUPAKAN BENTUK KESEPAKATAN YANG MERUPAKAN PERJANJIAN
        MENGIKAT ANTARA PENGGUNA DENGAN ECLIS. PENGGUNA SECARA SADAR DAN SUKARELA
        MENYETUJUI KETENTUAN INI UNTUK MENGGUNAKAN LAYANAN DI PLATFORM ECLIS.
      </p>

      <br />
      <h4>
        <strong>Definisi</strong>
      </h4>
      <ol>
        <li>
          <strong>Pengguna </strong>adalah pihak (terdaftar/tidak terdaftar) yang
          mengakses Platform eCLIS, yang wajib mematuhi Aturan Penggunaan eCLIS
          beserta ketentuan-ketentuan lain termasuk namun tidak terbatas pada
          Kebijakan Privasi eCLIS.
        </li>
        <li>
          <strong>Akun </strong>adalah kumpulan informasi Pengguna yang
          setidak-tidaknya terdiri dari <em>username</em>, <em>password</em>, dan{' '}
          <em>email</em> yang wajib diisi oleh Pengguna Terdaftar
        </li>
        <li>
          <strong>Layanan </strong>adalah konten, fitur, layanan, dan fungsi apa pun
          yang tersedia atas nama eCLIS yang diperuntukan untuk Pengguna eCLIS.
        </li>
        <li>
          <strong>Platform </strong>adalah situs resmi eCLIS yaitu{' '}
          <a href="https://eclis.id/">https://eclis.id/</a> beserta domain turunannya
          yang dapat diakses baik dari perangkat komputer maupun perangkat seluler
          Pengguna.
        </li>
        <li>
          <strong>Konten </strong>adalah segala bentuk informasi yang dimasukkan dan
          diterbitkan pada Platform eCLIS.
        </li>
        <li>
          <strong>Sistem </strong>
          <strong>eCLIS </strong>adalah Sistem Elektronik yang memproses, menelusuri
          dan menampilkan konten peraturan di Indonesia (Peraturan
          Perundang-undangan, Rancangan Peraturan Perundang-undangan, Komentar,
          Analisis dan Evaluasi terhadap Peraturan Perundang-undangan, Tulisan
          Ilmiah, dsb).
        </li>
        <li>
          <strong> Pengelola eCLIS </strong>adalah pengelola Sistem eCLIS yang
          bekerja guna menyelenggarakan dan meningkatkan kualitas Layanan eCLIS.
        </li>
        <li>
          <strong>Pihak Ketiga </strong>adalah pihak ketiga manapun di luar Pengguna
          dan Pengelola eCLIS serta pihak yang ditunjuk Pengelola eCLIS berdasarkan
          perjanjian untuk mengelola eCLIS yang dapat berbentuk perseorangan, badan
          usaha, badan hukum maupun entitas lainnya.
        </li>
      </ol>
      <br />

      <h4>Pengguna, Akun, Keamanan, dan Password</h4>
      <p>
        Berikut merupakan persyaratan yang wajib dipenuhi Pengguna untuk dapat
        memanfaatkan layanan eCLIS:
      </p>
      <ol>
        <li>
          Pengguna wajib membaca, memahami serta mengikuti semua ketentuan yang
          diatur dalam Aturan Penggunaan ini.
        </li>
        <li>
          Pengguna yang memiliki akun di Platform eCLIS, wajib mengisi data pribadi
          secara lengkap sesuai dengan identitas pribadinya di halaman profil eCLIS.
        </li>
        <li>
          Pengguna wajib menyampaikan informasi yang benar, tepat, lengkap dan
          terbaru dari diri Pengguna dalam rangka penggunaan Platform eCLIS dari
          waktu ke waktu.
        </li>
        <li>
          Pengguna wajib memahami bahwa detail informasi akun milik Pengguna adalah
          rahasia, dan karenanya Pengguna tidak akan mengungkapkan detail informasi
          akun Pengguna kepada Pihak Ketiga mana pun. Pengguna setuju untuk
          menanggung setiap risiko terkait pengungkapan informasi Akun Pengguna
          kepada Pihak Ketiga mana pun oleh dan bertanggung jawab penuh atas setiap
          konsekuensi yang berkaitan dengan hal tersebut.
        </li>
        <li>
          Pengguna dilarang menggunakan <em>robot</em>, <em>spider</em>, proses atau
          sarana untuk mengakses layanan untuk tujuan apapun, atau perangkat otomatis
          lainnya, termasuk namun tidak terbatas untuk memantau atau menyalin setiap
          bahan yang ada pada layanan di Platform eCLIS.
        </li>
        <li>
          Pengguna bertanggung jawab dan wajib menjaga keamanan dari akun termasuk
          namun tidak terbatas pada penggunaan email dan password milik Pengguna.
        </li>
        <li>
          Dalam hal Pengguna melakukan pelaporan dan/atau aduan atas layanan di
          Platform eCLIS dan/atau Aturan Penggunaan, maka setiap laporan dan/atau
          aduan tersebut wajib disampaikan dengan itikad baik dan dengan tujuan
          menyelesaikan masalah.
        </li>
        <li>
          Pengguna dilarang menggunakan Platform eCLIS untuk melanggar peraturan yang
          ditetapkan oleh hukum di Indonesia maupun di negara lainnya.
        </li>
        <li>
          Pengguna wajib menghargai hak-hak Pengguna lainnya dengan tidak memberikan
          informasi pribadi ke pihak lain tanpa izin pihak yang bersangkutan.
        </li>
        <li>
          eCLIS berhak sewaktu-waktu tanpa pemberitahuan terlebih dahulu kepada
          Pengguna untuk memblokir penggunaan sistem jika Pengguna melanggar
          peraturan perundang-undangan yang berlaku di wilayah Indonesia.
        </li>
        <li>
          Pengguna dilarang mendistribusikan virus atau teknologi lainnya yang dapat
          membahayakan eCLIS, kepentingan dan/atau properti dari Pengguna eCLIS,
          maupun instansi Pemerintahan.
        </li>
        <li>
          Apabila di kemudian hari ditemukan pelanggaran terkait Aturan Penggunaan
          oleh Pengguna, eCLIS berhak melakukan tindakan yang dianggap perlu demi
          menjaga keamanan dan kenyamanan Pengguna lain.
        </li>
        <li>
          Pengguna wajib bertanggung jawab penuh atas seluruh Konten yang Pengguna
          unggah, kirim melalui email, kirimkan dan/atau sediakan melalui Platform
          eCLIS.
        </li>
        <li>
          Pengguna dengan ini menyatakan bahwa eCLIS tidak bertanggung jawab atas
          kerugian ataupun kendala yang timbul atas penyalahgunaan akun Pengguna yang
          diakibatkan oleh kelalaian Pengguna, termasuk namun tidak terbatas pada
          meminjamkan atau memberikan akses akun kepada pihak lain, mengakses link
          atau tautan yang diberikan oleh pihak lain, memberikan atau memperlihatkan
          Kode Verifikasi, password atau email kepada pihak lain, maupun kelalaian
          Pengguna lainnya yang mengakibatkan kerugian ataupun kendala pada akun
          Pengguna.
        </li>
        <li>
          Pengguna menjamin bahwa identitas/data pribadi yang Pengguna daftarkan
          dalam pembuatan akun eCLIS merupakan identitas/data pribadi milik Pengguna
          sendiri bukan milik pihak manapun.
        </li>
        <li>
          Pengguna menjamin tidak akan melakukan tindakan apapun yang dapat
          mempengaruhi/menurunkan/mengganggu layanan, perangkat lunak, perangkat
          keras, sistem, jaringan yang digunakan Pengguna lain untuk mengakses
          Platform eCLIS.
        </li>
        <li>
          Pengguna menjamin tidak akan melakukan tindakan apapun yang dapat
          mengganggu dan/atau berdampak buruk pada eCLIS, jaringan internet,
          infrastruktur atau produk eCLIS, serta yang berdampak merugikan bagi
          keberlangsungan kegiatan usaha eCLIS.
        </li>
        <li>
          Pengguna dilarang menggunakan Platform eCLIS dengan tujuan mengumpulkan
          data pribadi Pengguna dengan tujuan melanggar Kebijakan Privasi dan
          peraturan perundang-undangan yang berlaku di Indonesia.
        </li>
      </ol>

      <br />
      <h4>
        <strong>Konten</strong>
      </h4>
      <ol>
        <li>
          Setiap Pengguna bertanggung jawab penuh secara pribadi dan penuh atas
          konten yang ditulis dan diterbitkannya di Platform eCLIS, dan Pengguna
          setuju untuk membebaskan serta tidak membebani eCLIS dan pengurusnya atas
          segala klaim yang terjadi yang disebabkan oleh konten yang ditulis dan
          diterbitkannya di eCLIS.
        </li>
        <li>
          Dikarenakan Proses kerja bahasa pemrograman computer yang digunakan oleh
          eCLIS mengharuskan data untuk diproses oleh eCLIS sebelum di unggah kedalam
          pangkalan data eCLIS, maka jika terdapat salah ketik dan kekurangan atas
          isi dari data, mohon kiranya Pengguna dapat langsung menghubungi eCLIS
          untuk dapat eCLIS perbaiki kesalahan tersebut.
        </li>
        <li>
          eCLIS berhak untuk memberikan segala informasi yang dimiliki tentang diri
          Pengguna pada saat terjadi permasalahan hukum yang disebabkan oleh konten
          yang ditulis dan diterbitkan oleh Pengguna. Harap diperhatikan bahwa
          seluruh data aktivitas akan direkam untuk semua konten yang ditulis dan
          diterbitkan.
        </li>
        <li>
          eCLIS tidak dapat dituntut untuk segala pernyataan, kekeliruan,
          ketidaktepatan atau kekurangan pada segala konten yang diterbitkan oleh
          Pengguna di eCLIS.
        </li>
        <li>
          eCLIS tidak mempunyai tanggung jawab hukum atas ketepatan isi informasi
          dari Pengguna eCLIS.
        </li>
        <li>
          Dengan menggunakan Layanan eCLIS, Pengguna setuju untuk tidak
          mempublikasikan konten yang:
        </li>
        <ul>
          <li>
            Mempromosikan/menyebarluaskan informasi yang diketahui salah, menyesatkan
            atau mempromosikan kegiatan ilegal atau perbuatan yang menyalahgunakan,
            mengancam, cabul, mencemarkan atau memfitnah;
          </li>
          <li>
            Mengandung prasangka dan kebencian terkait dengan suku, agama, ras, dan
            antar golongan (SARA), serta menganjurkan tindakan kekerasan;
          </li>
          <li>
            Diskriminatif atas dasar perbedaan jenis kelamin dan bahasa, serta tidak
            merendahkan martabat orang lemah, miskin, sakit, cacat jiwa, atau cacat
            jasmani;
          </li>
          <li>Mengirimkan SPAM;</li>
          <li>
            Melanggar hak cipta, paten, merek atau hak pribadi dan kepemilikan
            intelektual lainnya dari pihak ketiga, dan diterbitkan hanya dengan izin
            daripada pihak ketiga tersebut.
          </li>
        </ul>
        <li>
          Apabila Pengguna tidak mampu memenuhi ketentuan penggunaan ini, maka eCLIS
          berhak untuk melakukan tindakan lebih lanjut. Bagi Pengguna yang melanggar
          ketentuan penggunaan ini dapat dihentikan keanggotaannya atau aksesnya.
        </li>
      </ol>

      <br />
      <h4>
        <strong>Data Pengguna</strong>
      </h4>
      <ol>
        <li>
          Pengguna dengan ini sepakat dan setuju bahwa eCLIS dapat mengumpulkan,
          menggunakan, mengakses, menyimpan, dan/atau memproses data Pengguna
          termasuk namun tidak terbatas pada informasi pribadi, isi percakapan,
          dan/atau informasi tentang penggunaan layanan oleh Pengguna di Platform
          eCLIS.
        </li>
        <li>
          Sejauh diizinkan oleh hukum yang berlaku, eCLIS berhak menggunakan
          keseluruhan atau sebagian data yang diperoleh dan dikumpulkan dari Pengguna
          melalui Platform eCLIS untuk kepentingan pengembangan dan peningkatan mutu
          demi keamanan dan kenyamanan Pengguna.
        </li>
        <li>
          eCLIS berkewajiban untuk mengungkapkan data Pengguna bila dalam rangka
          proses hukum dan/atau permintaan pihak lembaga pemerintahan, penegak hukum,
          dan pihak berwenang resmi lainnya yang ditetapkan oleh peraturan
          perundang-undangan yang berlaku di Indonesia.
        </li>
        <li>
          Ketentuan lebih lanjut mengenai privasi dan data pribadi pengguna dapat
          dilihat di Kebijakan Privasi
        </li>
      </ol>

      <br />
      <h4>
        <strong>
          Pembatasan Tanggung Jawab atas Konten Peraturan Perundang-Undangan
        </strong>
      </h4>
      <p>
        eCLIS menghimpun database perundang-undangan sebagai bagian dari informasi
        publik dari berbagai situs resmi Pemerintah sebagai pelaksanaan hak atas
        informasi publik dan membantu Pemerintah dalam menyelenggarakan Keterbukaan
        Informasi Publik dengan memasukan konten peraturan perundang-undangan ke
        dalam Sistem eCLIS sesuai dengan salinan resmi dari situs pemerintah. eCLIS
        hanya bertanggungjawab terhadap kesalahan teknis yang dilakukan oleh
        Pengelola eCLIS dalam memasukan data perundang-undangan dan tidak
        bertanggungjawab atas kesalahan yang terdapat pada salinan resmi pemerintah.
        Apabila terdapat kesalahan dalam konten peraturan perundang-undangan,
        silahkan laporkan kepada Pengelola eCLIS di{' '}
        <a href="mailto:customercare@eclis.id">customercare@eclis.id</a>.
      </p>

      <br />
      <h4>
        <strong>Hukum yang Berlaku dan Penyelesaian Sengketa</strong>
      </h4>
      <p>
        Perjanjian ini akan diatur oleh dan ditafsirkan sesuai dengan hukum Republik
        Indonesia, tanpa memperhatikan pertentangan aturan hukum. Pengguna setuju
        bahwa tindakan hukum apapun atau sengketa yang mungkin timbul dari,
        berhubungan dengan, atau berada dalam cara apapun berhubungan dengan Platform
        eCLIS dan/atau Aturan Penggunaan ini akan diselesaikan secara eksklusif
        melalui BANI (Badan Arbitrase Nasional Indonesia).
      </p>

      <br />
      <h4>
        <strong>Ganti Rugi</strong>
      </h4>
      <p>
        Pengguna setuju untuk melepaskan eCLIS dari tuntutan ganti rugi dan menjaga
        eCLIS (termasuk perusahaan terafiliasi, direktur, komisaris, pejabat, serta
        seluruh karyawan dan agen) dari segala klaim atau tuntutan, kewajiban,
        kerugian, dan biaya hukum yang wajar, yang dilakukan oleh Pihak Ketiga yang
        timbul akibat kesalahan Pengguna termasuk namun tidak terbatas pada
        pelanggaran Aturan Penggunaan ini, penggunaan Layanan eCLIS di Platform eCLIS
        yang tidak semestinya, pelanggaran terhadap hak Pihak Ketiga, pelanggaran
        Kebijakan Privasi Eclis dan/atau pelanggaran Pengguna terhadap peraturan
        perundang-undangan yang berlaku di Republik Indonesia
      </p>

      <br />
      <h4>
        <strong>Sanksi</strong>
      </h4>
      <p>
        eCLIS memiliki kewenangan untuk mengambil tindakan yang dianggap perlu
        terhadap akun Pengguna, termasuk akun yang diduga dan/atau terindikasi
        melakukan penyalahgunaan, memanipulasi, dan/atau melanggar Aturan Penggunaan
        eCLIS, mulai dari memberikan teguran, membatasi atau mengakhiri hak setiap
        Pengguna untuk menggunakan layanan, maupun menutup akun tersebut tanpa
        memberikan pemberitahuan atau informasi terlebih dahulu kepada pemilik akun
        yang bersangkutan.
      </p>
    </div>
  )
}
export default TOS
