import React from 'react'
import styled from 'styled-components'
import color from 'assets/css/colors.scss'
import illustration from 'assets/img/something-wrong.svg'
import { Button } from 'components/Buttons'

const GlobalErrorFallback = () => {
  const handleClick = () => {
    window.location.replace(`${window.location.origin}/`)
  }

  return (
    <Div>
      <div className="left">
        <div className="container">
          <h1>
            Gagal <br /> Memuat Halaman
          </h1>
          <br />
          <p>
            Kesalahan ada pada kami dan akan segara kami perbaiki. Silahkan muat
            ulang halaman atau coba lagi lain waktu.
          </p>

          <br />

          <Button size="jumbo" onClick={handleClick} set="primary">
            Kembali ke Halaman Utama
          </Button>
        </div>
      </div>
      <div className="right">
        <img alt="something-wrong" src={illustration} />
      </div>
    </Div>
  )
}

export default GlobalErrorFallback

const Div = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  height: 100vh;
  width: 100%;

  .left {
    background-color: #fff !important;
    display: flex;
    justify-content: center;

    .container {
      padding: 6rem 0rem;
      max-width: 600px;

      h1 {
        font-size: 3.5rem;
      }

      p {
        font-weight: 300;
        max-width: 600px;
        font-size: 18px;
        line-height: 1.75;
      }
    }
  }

  .right {
    background-color: ${color.bgBlue};
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: auto;
      height: auto;
      max-height: 400px;
    }
  }

  @media screen and (max-width: 1100px) {
    grid-template-columns: repeat(auto-fill, minmax(600px, 2fr));

    .left {
      order: 2;

      .container {
        padding: 2rem 0rem;
        text-align: center;

        h1 {
          font-size: 3rem;
        }

        p {
          max-width: 300px;
          max-width: unset;
        }
      }
    }

    .right {
      order: 1;
      background-color: #fff !important;

      img {
        max-height: 300px;
      }
    }
  }

  @media screen and (max-width: 600px) {
    grid-template-columns: repeat(auto-fill, minmax(300px, max-content));

    .left {
      .container {
        padding: 1rem;

        h1 {
          font-size: 2rem;
        }

        p {
          font-size: 16px;
        }
      }
    }

    .right {
      img {
        max-height: 230px;
      }
    }
  }
`
