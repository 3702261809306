const theme = {
  color: {
    primary: '#327dfa',
    primaryDark: '#0050DB',
    primaryDarker: '#054cc7',
    primaryFooter: '#2E72E4',
    // primaryHighlight : rgba('#327dfa', .1),

    // 33ccb0
    // 0EBE95
    accent: '#1C0DBF',
    accentDark: '#150A8F',

    success: '#2ED47A',
    warning: '#FFB946',
    danger: '#ff1a21',
    dangerDark: '#F20010',

    premium: '#6E15FF',
    neutral: '#ABABAB',
    neutralDark: '#7D7D7D',
    neutralSofter: '#F3F4F6',

    bgblue: '#EFF3F9',
    bgsidebar: '#242E42',
    neutralSidebar: '#8D9DB8',
    bgTextarea: '#f5f5f5',
    black: '#333333',
    placeholder: '#878787',
    // highlight : rgba('#F6EB03', .3),
    outline: '#e6e6e6',
    formoutline: '#2D8FEB',
  },
}

export default theme
