import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import color from 'assets/css/colors.scss'
import { MOBILE_SCREEN } from 'Constants/display'
import { connect } from 'react-redux'
import {
  icon_nav_toggle,
  icon_chevronDown,
  icon_times,
} from 'components/FontAwesome'
import Logo from '../Logo'

import { Tabs, Tab, TabContent } from 'components/TabsHeader'
import ReactModal from 'components/Modal'
import TOS from 'components/Policy/TOS'
import PrivacyPolicy from 'components/Policy/PrivacyPolicy'
import RefundPolicy from 'components/Policy/RefundPolicy'
import SubMenuJasa from './SubMenuJasa'
import SubMenuProduk from './SubMenuProduk'
import SubMenuMitra from './SubMenuMitra'

const PublicNavbar = ({ isSubDomain }) => {
  const [isOpen, setOpen] = useState(false)
  const [modalIsOpen, setModalOpen] = useState(false)
  const isMobile = useMediaQuery({ query: MOBILE_SCREEN })
  const location = useLocation()

  useEffect(() => {
    setOpen(false)
  }, [location.pathname])

  let dontRender
  let condition

  if (isSubDomain) {
    dontRender = [
      /login/,
      /register/,
      /register\/confirm/,
      /forgot/,
      /forgot\/confirm/,
      /mitra/,
    ]
    condition =
      isSubDomain && dontRender.some((x) => x.test(location.pathname))
        ? 'none'
        : 'block'
  }
  // else {
  //     dontRender = [/mitra/]
  //     condition = dontRender.some((x) => x.test(location.pathname)) ? "none" : "block";
  // }

  return (
    <NavEclis style={{ display: condition }}>
      <span className="eclis_nav_toggle" onClick={() => setOpen(!isOpen)}>
        {isOpen ? icon_times : icon_nav_toggle}
      </span>

      <Wrapper>
        <div className="eclis_brand">
          <Link to="/">
            <Logo />
          </Link>
        </div>
        <ul className={`main-nav ${isOpen ? 'active' : ''}`}>
          <li>
            <Link to="/register"> Daftar </Link>
          </li>
          <li>
            <Link to="/login"> Masuk </Link>
          </li>
          {!isMobile && (
            <li>
              <Separator />
            </li>
          )}
          <li>
            <Link to="/karir"> Karir </Link>
          </li>
          {/* <li onClick={() => setModalOpen(true)}> Kebijakan Kami </li> */}
          <li>
            <Link to="/privacy-policy">Kebijakan Kami</Link>
          </li>
          {/* <li>
            <Link to="/mitra-daerah"> Mitra Daerah </Link>
          </li> */}
          <li>
            Jasa {icon_chevronDown}
            {isMobile ? (
              <ul>
                <li>
                  <Link to="/jasa/pengguna-individu"> Pengguna Individu </Link>
                </li>
                <li>
                  <Link to="/jasa/organisasi"> Organisasi </Link>
                </li>
              </ul>
            ) : (
              <SubMenuJasa />
            )}
          </li>
          <li>
            Produk {icon_chevronDown}
            {isMobile ? (
              <ul>
                <li>
                  <Link to="/produk/himpunan-catatan"> Mesin Pencarian </Link>
                </li>
                <li>
                  <Link to="/produk/mesin-pencarian"> Himpunan Catatan </Link>
                </li>
              </ul>
            ) : (
              <SubMenuProduk />
            )}
          </li>
          <li>
            Mitra {icon_chevronDown}
            {isMobile ? (
              <ul>
                <li>
                  <Link to="/mitra-daerah"> Daerah </Link>
                </li>
                <li>
                  <Link to="/mitra-universitas"> Universitas </Link>
                </li>
              </ul>
            ) : (
              <SubMenuMitra />
            )}
          </li>
        </ul>
      </Wrapper>

      <ReactModal
        title="Kebijakan Kami"
        isOpen={modalIsOpen}
        onRequestClose={() => setModalOpen(false)}
      >
        <Tabs>
          <PrivacyTab>
            <Tab id="tos">Ketentuan Penggunaan</Tab>
            <Tab id="privacy">Kebijakan Privasi</Tab>
            <Tab id="refund">Pengembalian Pembayaran</Tab>
          </PrivacyTab>

          <br />
          <br />

          <TabContent forId="tos">
            {' '}
            <TOS />{' '}
          </TabContent>
          <TabContent forId="privacy">
            {' '}
            <PrivacyPolicy />{' '}
          </TabContent>
          <TabContent forId="refund">
            {' '}
            <RefundPolicy />{' '}
          </TabContent>
        </Tabs>
      </ReactModal>
    </NavEclis>
  )
}

const mapStateToProps = (state) => {
  return {
    isSubDomain: state.isSubDomain,
  }
}

export default connect(mapStateToProps)(PublicNavbar)

const PrivacyTab = styled.div`
  width: 100%;
  text-align: center;

  div {
    display: inline-block;
    margin-right: 1.5rem;
    font-weight: 600;
    padding-bottom: 0.5rem;
    color: ${color.neutral};
    transition: color 0.2s ease;

    &.isActive {
      color: ${color.primary};
      border-bottom: 2px solid ${color.primary};
    }

    &:hover {
      color: ${color.primary};
    }
  }
`

const NavEclis = styled.nav`
  user-select: none;
  position: relative;
  background-color: ${color.primary};
  height: 100%;

  .eclis_nav_toggle {
    font-size: 26px;
    position: absolute;
    right: 1.5rem;
    top: 25px;
    color: #fff;
    cursor: pointer;
  }

  @media screen and (min-width: 768px) {
    height: 75px;

    .eclis_nav_toggle {
      display: none;
    }
  }
`

const Wrapper = styled.div`
  padding: 1rem 1.5rem;
  display: block;
  width: 100%;

  .eclis_brand {
    display: flex;
    align-items: center;
  }

  ul.main-nav {
    padding-inline-start: 0.5rem;
    max-height: 0px;
    overflow: hidden;
    transition: all 0.3s ease;

    li {
      list-style-type: none;
      color: #fff;
      font-weight: 600;
      padding: 0.2rem 0rem;

      svg {
        font-size: 14px;
      }

      a {
        color: #fff !important;

        &:hover {
          text-decoration: none;
        }
      }
    }

    &.active {
      margin-top: 1rem;
      overflow: hidden;
      max-height: 500px !important;
    }
  }

  /* DESKTOP VERSION */
  @media screen and (min-width: 768px) {
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;

    .eclis_brand {
      display: flex;
      align-items: center;
    }

    ul.main-nav {
      margin-top: 0rem;
      display: flex;
      flex-direction: row;
      overflow: visible;

      > li {
        position: relative;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        padding-bottom: 3rem;
        cursor: pointer;

        a {
          color: #fff !important;
          font-weight: 600;

          &:hover {
            text-decoration: none;
          }
        }

        ul {
          color: ${color.black};
          margin-block-start: 0rem !important;
          margin-block-end: 0rem !important;
          display: none;

          li {
            margin-left: 0rem;
            padding-bottom: 0rem;

            a {
              color: ${color.black} !important;
              padding: 0rem;
            }
          }
        }

        &:hover {
          ul {
            display: block;
          }
        }

        &:nth-child(1) {
          order: 5;
          a {
            padding: 0.9rem 1.75rem !important;
            border-radius: 25px !important;
            background-color: ${color.primaryDark};
            transition: all 0.2s ease-in-out;

            &:hover {
              background-color: ${color.primaryDark};
            }
          }
        }
        &:nth-child(2) {
          order: 4;
        }
        &:nth-child(3) {
          order: 3;
        }
        &:nth-child(4) {
          order: 2;
        }
        &:nth-child(5) {
          order: 1;
        }
      }

      &.active {
        overflow: visible;
        max-height: 500px;
      }
    }
  }
`

const Separator = styled.div`
  width: 2px;
  height: 35px;
  background-color: #fff;
`
