import React, { useMemo } from 'react'
import { rgba } from 'polished'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'
import { MOBILE_SCREEN } from 'Constants/display'

import Logo from '../Logo'
import LogoBuku from '../LogoBuku'

const NavLogo = ({ isLoading }) => {
  const isMobile = useMediaQuery({ query: MOBILE_SCREEN })

  return useMemo(() => {
    if (isLoading) {
      return (
        <SkeletonTheme color={rgba('#fff', 0.2)} highlightColor={rgba('#fff', 0.1)}>
          <Skeleton width={150} height={38} />
        </SkeletonTheme>
      )
    }

    return (
      <div>
        <Link to="/">{isMobile ? <LogoBuku /> : <Logo />}</Link>
      </div>
    )
  }, [isLoading, isMobile])
}

export default NavLogo
