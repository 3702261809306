import { setLastVisit } from 'helper'

export const isLogin = (state = false, action) => {
  switch (action.type) {
    case 'LOGIN':
      return true

    case 'LOGOUT':
      if (action.payload && action.payload.saveLastUrl) {
        setLastVisit()
      }

      return false

    default:
      return state
  }
}
