import React from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'
import Modal from 'react-modal'
import BarLoader from 'react-spinners/BarLoader'
import color from '../../assets/css/colors.scss'
import './modal.scss'

import Icon, { icon_times } from '../FontAwesome'
import LoadingModal from './LoadingModal'

const ReactModal = ({
  title,
  style,
  isOpen,
  onRequestClose,
  className,
  overlayClassname,
  isLoading = false,
  isUpdating = false,
  children,
  ...props
}) => {
  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      contentLabel={title}
      className={className || 'modal_custom_default'}
      overlayClassName={overlayClassname || 'modal_overlay'}
      style={{ content: style }}
      onRequestClose={() => onRequestClose()}
      {...props}
    >
      <div className="modal_container">
        <div className="modal_header">
          <div className="header_container">
            <div className="title">
              <h5>{title || 'Masukan Judul Modal'}</h5>
            </div>

            <div className="action">
              <Icon
                icon={icon_times}
                size="18px"
                hoverColor={color.neutral}
                onClick={() => onRequestClose()}
              />
            </div>
          </div>

          <div className="header_loading">
            {isUpdating && (
              <BarLoader height={2} width="100%" color={color.accent} />
            )}
          </div>
        </div>

        <div className="modal_body">
          {children}
          {isUpdating && <Updating />}
          {isLoading && <LoadingModal />}
        </div>
      </div>
    </Modal>
  )
}
export default ReactModal

const Updating = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: ${rgba('#ffffff', 0.4)};
`
